import React, { useState, Fragment } from 'react'
import { AiOutlineMenu, AiOutlineClose } from "react-icons/ai";
import { Link, animateScroll as scroll } from "react-scroll";
// import logo from "../public/images/home/logoHeader.png";

const DropDownItem = (props) => {
    <a href="#">
        {props.children}
    </a>
}

const DropDownMenu = () => {
    <div className="dropdown">
        <DropDownItem> About </DropDownItem>
        <DropDownItem> Contact </DropDownItem>
    </div>
}

const NavItem = (props) => {
    const [open, setOpen] = useState(false);
    return (
        <li className="nav-item">
            <a href="#" onClick={() => { setOpen(!open) }}>
                <label>menu</label>
            </a>
            <label> {open ? "hello" : "eddsd"}</label>
            {open && <DropDownMenu></DropDownMenu>}
        </li>
    )
}

const NavBar = (props) => {
    const scrollToTop = () => {
        scroll.scrollToTop();
    };
    const [open, setOpen] = useState(false);


    return (
        <Fragment>
            <nav className="nav d-none d-lg-block" id="navbar">
                <div className="nav-content">
                <img className='nav-logo' src="/images/Home/LogoHeader.png"></img>

                    {/* <img
                        src={logo}
                        className="nav-logo"
                        alt="Logo"
                        onClick={scrollToTop}
                    /> */}
                    <ul className="nav-items">
                        <li className="nav-item">
                            <Link
                                activeClass="active"
                                to="Inicio"
                                spy={true}
                                smooth={true}
                                offset={-70}
                                duration={500}
                            >
                                Inicio
                            </Link>
                        </li>
                        <li className="nav-item">
                            <Link
                                activeClass="active"
                                to="Inversiones"
                                spy={true}
                                smooth={true}
                                offset={-70}
                                duration={500}
                            >
                                Inversiones
                            </Link>
                        </li>
                        <li className="nav-item">
                            <Link
                                activeClass="active"
                                to="Nosotros"
                                spy={true}
                                smooth={true}
                                offset={-70}
                                duration={500}
                            >
                                Nosotros
                            </Link>
                        </li>
                        <li className="nav-item">
                            <Link
                                activeClass="active"
                                to="Contacto"
                                spy={true}
                                smooth={true}
                                offset={-70}
                                duration={500}
                            >
                                Contacto
                            </Link>
                        </li>
                    </ul>
                </div>
            </nav>
            <nav className="nav d-lg-none">
                <div className="nav-content" >
                <img className='nav-logo' src="/images/Home/LogoHeader.png"></img>
                
                    {
                        !open ?
                        <AiOutlineMenu key={'open' + open} size='25px' color='#1e3a72' onClick={() => { setOpen(true) }} className="menu-mobile"/>   : 
                        <AiOutlineClose key={'close' + open} size='25px' color='#1e3a72' className="menu-mobile" onClick={() => { setOpen(false) }}/>
                    } 
                    
                </div>
                {open &&
                    <div className='dropdown'>
                        <ul className="nav-items">
                            <li className="nav-item">
                                <Link
                                    activeClass="active"
                                    to="Inicio"
                                    spy={true}
                                    smooth={true}
                                    offset={-70}
                                    duration={500}
                                >
                                    Inicio
                                </Link>
                            </li>
                            <li className="nav-item">
                                <Link
                                    activeClass="active"
                                    to="Inversiones"
                                    spy={true}
                                    smooth={true}
                                    offset={-70}
                                    duration={500}
                                >
                                    Inversiones
                                </Link>
                            </li>
                            <li className="nav-item">
                                <Link
                                    activeClass="active"
                                    to="Nosotros"
                                    spy={true}
                                    smooth={true}
                                    offset={-70}
                                    duration={500}
                                >
                                    Nosotros
                                </Link>
                            </li>
                            <li className="nav-item">
                                <Link
                                    activeClass="active"
                                    to="Contacto"
                                    spy={true}
                                    smooth={true}
                                    offset={-70}
                                    duration={500}
                                >
                                    Contacto
                                </Link>
                            </li>
                        </ul>
                    </div>
                }
            </nav>
        </Fragment>
    )
}

export default NavBar;