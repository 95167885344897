export const GMMCategory= {
    name:"GMM",
    sections: [
        {
            name: "Línea Azul",
            products: [
                {name:"VIP", id:"VIPSection"},
                 {name:"Internacional", id:"InternationalSection"},
                 {name:"GNP Enlace Internacional", id:"GNPLinkSection"},
                 {name:"Alta Especialidad", id:"SpecialitySection"},
                 {name:"Vínculo Mundial", id:"WorldLinkSection"},
                // {name:"Premium", id:"PremiumSection"},
                // {name:"Platino", id:"PlatinumSection"},
                // {name:"Flexible (Índigo, Ámbar y Cuarzo)", id:"FlexSection"},
                // {name:"Esencial (200 y 300)", id:"EsentialSection"},
                // {name:"Versátil", id:"VersatileSection"},
                // {name:"Conexión", id:"ConnectionSection"},
                // {name:"Plenitud", id:"FullnessSection"},
                // {name:"Acceso", id:"AccessSection"}         
            ]
        },
		{
			name: "Línea Azul",
            products: [
                
               // {name:"Internacional", id:"InternationalSection"},
                // {name:"GNP Enlace Internacional", id:"GNPLinkSection"},
                // {name:"Alta Especialidad", id:"SpecialitySection"},
                // {name:"Vínculo Mundial", id:"WorldLinkSection"},
                 {name:"Premium", id:"PremiumSection"},
                 {name:"Platino", id:"PlatinumSection"},
                 {name:"Flexible (Índigo, Ámbar y Cuarzo)", id:"FlexSection"},
                 {name:"Esencial (200 y 300)", id:"EsentialSection"},
                // {name:"Versátil", id:"VersatileSection"},
                // {name:"Conexión", id:"ConnectionSection"},
                // {name:"Plenitud", id:"FullnessSection"},
                // {name:"Acceso", id:"AccessSection"}         
            ]
        },
		{
			name: "Línea Azul",
            products: [
               
               // {name:"Internacional", id:"InternationalSection"},
                // {name:"GNP Enlace Internacional", id:"GNPLinkSection"},
                // {name:"Alta Especialidad", id:"SpecialitySection"},
                // {name:"Vínculo Mundial", id:"WorldLinkSection"},
                //  {name:"Premium", id:"PremiumSection"},
                //  {name:"Platino", id:"PlatinumSection"},
                //  {name:"Flexible (Índigo, Ámbar y Cuarzo)", id:"FlexSection"},
                //  {name:"Esencial (200 y 300)", id:"EsentialSection"},
                {name:"Versátil", id:"VersatileSection"},
                {name:"Conexión", id:"ConnectionSection"},
                {name:"Plenitud", id:"FullnessSection"},
                {name:"Acceso", id:"AccessSection"}         
            ]
        }
    ]
};



export const VidaCategory= {
    name:"Vida",
    sections: [
        {
            name: "Ahorro",
            products: [
                { name:"Dotal", id: "DotalSection"},
				{ name:"Vida a tus sueños", id:"DreamsSection"},
				{ name:"Impulsa", id:"ImpulseSection"}
            ]
        },
        {
            name: "Educación",
            products: [
                { name:"Profesional", id:"ProfessionalSection"},
				{ name:"Profesional Abuelos", id:"ProGrandPaSection"}
            ]
        },
        {
            name: "Protección",
            products: [
                { name:"Platino", id:"PlatinumSection"},
				{ name:"Privilegio", id:"PrivilegeSection"}
            ]
        },
        {
            name: "Protección y Ahorro",
            products: [
                { name:"Ordinario de Vida", id:"OrdinaryLifeSection"},
				{ name:"Platino Universal", id:"UniversalSection"},
				{ name:"Privilegio Universal", id:"UniversalPrivilegeSection"},
				{ name:"Trasciende", id:"TranscendsSection"},
				{ name:"Vida Inversión", id:"InvestmentLifeSection"},
				{ name:"Visión Plus", id:"VisionPlusSection"}
            ]
        },
        {
            name: "Retiro",
            products: [
                { name:"Consolida", id:"ConsolidateSection"},
				{ name:"Consolida Total", id:"TotalConsolidateSection"},
				{ name:"Elige", id:"ChooseSection"},
				{ name:"Proyecta", id:"ProjectsSection"},
				{ name:"Proyecta Afecto", id:"AffectionSection"}
            ]
        },
        {
            name: "Multiestrategia",
            products: [
                { name:"Capitaliza", id:"CapitalizeSection"}
            ]
        },
        {
            name: "Salud en Vida",
            products: [
                { name:"Salud en Vida", id:"HealtLifeSection"}
            ]
        },
		{
            name: "Continuidad Patrimonial",
            products: [
                { name:"Continuidad Patrimonial", id:"ContinuitySection"}
            ]
        }
    ]

};

export const VIPSection = {
	title: 'VIP',

	info:[
		{
			title:"Descripción general",
			description:"Brinda beneficios exclusivos y ventajas únicas como:",
			more:[
				{
					text:"Protección completa e integral."
				},
				{
					text:"Amplia cobertura de hospitales y médicos en México y el extranjero."
				},
                {
					text:"Suite en los mejores hospitales de México."
				},
                {
					text:"Beneficio de pago directo."
				},
                {
					text:"Alta Suma Asegurada de maternidad."
				},
                {
					text:"Sin deducible ni coaseguro en caso de accidentes."
				},
                {
					text:"Suma Asegurada y Deducible único por padecimiento o por año calendario."
				}
			]
		},
		{
			title:"Perfil del cliente",
			more:[
				{
					text:"Edad de contratación desde el nacimiento hasta los 64 años."
				},
				{
					text:"Nivel socioeconómico A/B."
				}
			]
		},
		{
			title:"Plazo del seguro",
			more:[
				{
					text:"1 año."
				}
			]
		},
		{
			title:"Suma asegurada",
			more:[
				{
					text:"De 100,000 hasta 10,000,000 dls."
				}
			]
		},
		{
			title:"Deducible",
			more:[
				{
					text:"Desde 500 dls. en territorio nacional y 1,500 dls. en territorio extranjero."
				},
                {
					text:"Hasta 10,000 dls. en territorio nacional y 10,000 dls. en territorio extranjero."
				}
			]
		},
		{
			title:"Coaseguro",
			more:[
				{
					text:"De 0% a 20%."
				}
			]
		},
		{
			title:"Conducto de pago",
			more:[
				{
					text:"Intermediario o Bancario."
				}
			]
		}
	]
};

export const InternationalSection = {
	title: 'Internacional',

	info:[
		{
			title:"Descripción general",
			description:"Brinda beneficios y ventajas como:",
			more:[
				{
					text:"Amplia cobertura de hospitales y médicos en México y el extranjero."
				},
				{
					text:"Beneficio de pago directo."
				},
                {
					text:"Cobertura gratuita del recién nacido desde su nacimiento hasta la siguiente renovación."
				},
                {
					text:"Suite en los mejores hospitales de México."
				},
                {
					text:"Suma Asegurada y Deducible único por padecimiento o por año calendario."
				}
			]
		},
		{
			title:"Perfil del cliente",
			more:[
				{
					text:"Edad de contratación desde el nacimiento hasta los 64 años."
				},
				{
					text:"Nivel socioeconómico A/B."
				}
			]
		},
		{
			title:"Plazo del seguro",
			more:[
				{
					text:"1 año."
				}
			]
		},
		{
			title:"Suma asegurada",
			more:[
				{
					text:"De 100,000 hasta 10,000,000 dls."
				}
			]
		},
		{
			title:"Deducible",
			more:[
				{
					text:"Desde 500 dls. en territorio nacional y 1,500 dls. en territorio extranjero."
				},
                {
					text:"Hasta 10,000 dls. en territorio nacional y 10,000 dls. en territorio extranjero."
				}
			]
		},
		{
			title:"Coaseguro",
			more:[
				{
					text:"De 5% a 20%."
				}
			]
		},
		{
			title:"Conducto de pago",
			more:[
				{
					text:"Intermediario o Bancario."
				}
			]
		}
	]
};

export const GNPLinkSection = {
	title: 'GNP Enlace Internacional',

	info:[
		{
			title:"Descripción general",
			description:"Cobertura para tratamientos pre y post natales (incluidas en la cobertura de maternidad).",
			more:[
				{
					text:"Valoración y consultas para el tratamiento de padecimientos mentales."
				},
				{
					text:"Hasta una tercera opinión médica en el extranjero."
				},
                {
					text:"Cobertura de recién nacido sin costo."
				},
                {
					text:"Indemnización de cuidados preventivos: check up y vacunas."
				},
                {
					text:"Períodos de espera menores para la cobertura de ciertas enfermedades."
				},
                {
					text:"Acceso a la red de médicos de GNP de primer nivel."
				},
                {
					text:"Cobertura de cirugía profiláctica para prevenir que se presente cáncer de mama y/u ovario."
				},
                {
					text:"Deducible único por padecimiento."
				}
			]
		},
		{
			title:"Perfil del cliente",
			more:[
				{
					title:"El rango de edad para contratar este Seguro es:",
					text:""
				},
				{
					title:"Titular: ",
					text:"15 a 74 años.*"
				},
				{
					title:"Dependiente: ",
					text:"0 a 74 años."
				}
				,
				{
					text:"*Para edades entre 65 y 74 años, la contratación estará sujeta a la presentación de estudios médicos."
				}
			]
		},
		{
			title:"Plazo del seguro",
			more:[
				{
					text:"1 año."
				}
			]
		},
		{
			title:"Suma asegurada",
			more:[
				{
					text:"5,000,000 USD."
				}
			]
		},
		{
			title:"Deducible",
			more:[
				{
					text:"Desde 1,000 USD en territorio nacional y 1,000 USD en territorio extranjero."
				},
                {
					text:"Hasta 10,000 USD en territorio nacional y 10,000 USD en territorio extranjero."
				}
			]
		},
		{
			title:"Coaseguro",
			more:[
				{
					text:"5%."
				}
			]
		},
		{
			title:"Conducto de pago",
			more:[
				{
					text:"Intermediario o Bancario."
				}
			]
		}
	]
};

export const SpecialitySection = {
	title: 'Alta Especialidad',

	info:[
		{
			title:"Descripción general",
			description:"Brinda beneficios exclusivos y ventajas únicas como:",
			more:[
				{
					text:"Acceso a cualquier hospital del extranjero para un grupo de enfermedades y accidentes críticos."
				},
				{
					text:"Libre elección del médico de preferencia."
				},
                {
					text:"Eliminación de derechos de póliza (si se cuenta con una póliza de Gastos Médicos Mayores con GNP)."
				}
			]
		},
		{
			title:"Perfil del cliente",
			more:[
				{
					text:"Edad de contratación desde el nacimiento hasta los 70 años. *"
				},
				{
					text:"Nivel socioeconómico a partir de Cm."
				}
			]
		},
		{
			title:"Plazo del seguro",
			more:[
				{
					text:"1 año."
				}
			]
		},
		{
			title:"Suma asegurada",
			more:[
				{
					text:"500,000 y 1,000,000 dls."
				}
			]
		},
		{
			title:"Deducible",
			more:[
				{
					text:"1,500, 3,000 o 10,000 dls."
				}
			]
		},
		{
			title:"Coaseguro",
			more:[
				{
					text:"10%."
				}
			]
		},
		{
			title:"Conducto de pago",
			more:[
				{
					text:"Intermediario o Bancario."
				}
			]
		}
	]
};

export const WorldLinkSection = {
	title: 'Vínculo Mundial',

	info:[
		{
			title:"Descripción general",
			description:"Brinda beneficios y ventajas como:",
			more:[
				{
					text:"Amplia cobertura de hospitales y médicos en cualquier parte del mundo."
				},
				{
					text:"Eliminación de derecho de póliza. (Si cuenta con póliza Individual)."
				},
                {
					text:"Eliminación de periodos de espera. (Presentando comprobante de estancia por estudios o trabajo de al menos 3 meses)."
				}
			]
		},
		{
			title:"Perfil del cliente",
			more:[
				{
					text:"Edad de contratación desde el nacimiento hasta los 64 años."
				},
				{
					text:"Nivel socioeconómico a partir de C+"
				}
			]
		},
		{
			title:"Plazo del seguro",
			more:[
				{
					text:"La estancia en el extranjero no mayor a 1 año."
				}
			]
		},
		{
			title:"Suma asegurada",
			more:[
				{
					text:"Desde 50,000 hasta 1,000,000 dls."
				}
			]
		},
		{
			title:"Deducible",
			more:[
				{
					text:"0 hasta 2,500 dls."
				}
			]
		},
		{
			title:"Coaseguro",
			more:[
				{
					text:"0%, 10% y 20%."
				}
			]
		},
		{
			title:"Conducto de pago",
			more:[
				{
					text:"Intermediario o Bancario."
				}
			]
		}
	]
};

export const PremiumSection = {
	title: 'Premium',

	info:[
		{
			title:"Descripción general",
			description:"Brinda beneficios y ventajas como:",
			more:[
				{
					text:"Amplia cobertura de hospitales y médicos en México y el extranjero."
				},
				{
					text:"Beneficio de pago directo."
				},
                {
					text:"Cobertura gratuita del recién nacido desde su nacimiento hasta la siguiente renovación."
				},
                {
					text:"Suite en los mejores hospitales de México."
				},
                {
					text:"Suma Asegurada y Deducible único por padecimiento o por año calendario."
				}
			]
		},
		{
			title:"Perfil del cliente",
			more:[
				{
					text:"Edad de contratación desde el nacimiento hasta los 64 años."
				},
				{
					text:"Nivel socioeconómico A/B."
				}
			]
		},
		{
			title:"Plazo del seguro",
			more:[
				{
					text:"1 año."
				}
			]
		},
		{
			title:"Suma asegurada",
			more:[
				{
					text:"De 100,000 hasta 10,000,000 dls."
				}
			]
		},
		{
			title:"Deducible",
			more:[
				{
					text:"Desde 500 dls. en territorio nacional y 1,500 dls. en territorio extranjero."
				},
                {
					text:"Hasta 10,000 dls. en territorio nacional y 10,000 dls. en territorio extranjero."
				}
			]
		},
		{
			title:"Coaseguro",
			more:[
				{
					text:"De 5% a 20%."
				}
			]
		},
		{
			title:"Conducto de pago",
			more:[
				{
					text:"Intermediario o Bancario."
				}
			]
		}
	]
};

export const PlatinumSection = {
	title: 'Platino',

	info:[
		{
			title:"Descripción general",
			description:"Brinda beneficios y ventajas como:",
			more:[
				{
					text:"Atención en cualquier hospital de México."
				},
				{
					text:"Libre elección del médico."
				},
                {
					text:"Indemnización por Maternidad."
				},
                {
					text:"Ayuda para atención en el extranjero."
				},
                {
					text:"Suma Asegurada y Deducible única(o) por padecimiento o por año padecimiento."
				}
			]
		},
		{
			title:"Perfil del cliente",
			more:[
				{
					text:"Edad de contratación desde el nacimiento hasta los 70 años*."
				},
				{
					text:"Nivel socioeconómico C+."
				}
				,
				{
					text:"*Para edades entre 65 y 70 años, la contratación estará sujeta a la presentación de estudios médicos."
				}
			]
		},
		{
			title:"Plazo del seguro",
			more:[
				{
					text:"1 año."
				}
			]
		},
		{
			title:"Suma asegurada",
			more:[
				{
					text:"$675,000 hasta $146,300,000."
				}
			]
		},
		{
			title:"Deducible",
			more:[
				{
					text:"De $16,000 hasta $183,000."
				}
			]
		},
		{
			title:"Coaseguro",
			more:[
				{
					text:"De 5% a 20%."
				}
			]
		},
		{
			title:"Conducto de pago",
			more:[
				{
					text:"Intermediario o Bancario."
				}
			]
		}
	]
};

export const FlexSection = {
	title: 'Flexible (Índigo, Ámbar y Cuarzo)',

	info:[
		{
			title:"Descripción general",
			description:"Brinda beneficios y ventajas como:",
			more:[
				{
					text:"Cobertura en reconocidos hospitales de México."
				},
				{
					text:"Indemnización por Maternidad."
				},
                {
					text:"Libre elección del médico."
				},
                {
					text:"Atención médica en el extranjero, si lo requiere."
				},
                {
					text:"Suma Asegurada y Deducible única(o) por padecimiento o por año padecimiento."
				},
                {
					text:"Reducción o eliminación del coaseguro al atenderse en un hospital de un plan menor al contratado."
				}
				
			]
		},
		{
			title:"Perfil del cliente",
			more:[
				{
					text:"Edad de contratación desde el nacimiento hasta los 70 años*."
				},
				{
					text:"Nivel socioeconómico Cm."
				}
				,
				{
					text:"*Para edades entre 65 y 70 años, la contratación estará sujeta a la presentación de estudios médicos."
				}
			]
		},
		{
			title:"Plazo del seguro",
			more:[
				{
					text:"1 año."
				}
			]
		},
		{
			title:"Suma asegurada",
			more:[
				{
					text:"De $675,000 hasta $91,700,000."
				}
			]
		},
		{
			title:"Deducible",
			more:[
				{
					text:"De $16,000 hasta $183,000."
				}
			]
		},
		{
			title:"Coaseguro",
			more:[
				{
					text:"De 5% a 20%."
				}
			]
		},
		{
			title:"Conducto de pago",
			more:[
				{
					text:"Intermediario o Bancario."
				}
			]
		}
	]
};

export const EsentialSection = {
	title: 'Esencial (200 y 300)',

	info:[
		{
			title:"Descripción general",
			description:"Brinda beneficios y ventajas como:",
			more:[
				{
					text:"Atención médica privada de calidad a precios accesibles."
				},
				{
					text:"Plan Hospitalario con cobertura de todo lo médicamente necesario para la atención de un accidente o enfermedad."
				},
                {
					text:"Eliminación del coaseguro al atenderse en un hospital de un plan menor al contratado."
				}
				
			]
		},
		{
			title:"Perfil del cliente",
			more:[
				{
					text:"Edad de contratación desde el nacimiento hasta los 70 años*."
				},
				{
					text:"Nivel socioeconómico C- en adelante."
				},
				{
					text:"*Para edades entre 65 y 70 años, la contratación estará sujeta a la presentación de estudios médicos."
				}
			]
		},
		{
			title:"Plazo del seguro",
			more:[
				{
					text:"1 año."
				}
			]
		},
		{
			title:"Suma asegurada",
			more:[
				{
					text:"$4,295,000."
				}
			]
		},
		{
			title:"Deducible",
			more:[
				{
					text:"$17,000 y $28,500 por año padecimiento."
				}
			]
		},
		{
			title:"Coaseguro",
			more:[
				{
					text:"10%."
				}
			]
		},
		{
			title:"Conducto de pago",
			more:[
				{
					text:"Intermediario o Bancario."
				}
			]
		}
	]
};

export const VersatileSection = {
	title: 'Versátil',

	info:[
		{
			title:"Descripción general",
			description:"Brinda beneficios y ventajas como:",
			more:[
				{
					text:"Suma Asegurada por año póliza por Asegurado."
				},
				{
					text:"Acceso a cualquier hospital en México."
				},
                {
					text:"Deducible por año padecimiento."
				},
                {
					text:"El deducible y coaseguro están definidos de acuerdo al nivel hospitalario de atención que el Asegurado elija al momento de una eventualidad médica."
				}
				
			]
		},
		{
			title:"Perfil del cliente",
			more:[
				{
					text:"Edad de contratación desde el nacimiento hasta los 70 años*."
				},
				{
					text:"Nivel socioeconómico C- en adelante."
				}
				,
				{
					text:"*Para edades entre 65 y 70 años, la contratación estará sujeta a la presentación de estudios médicos."
				}
			]
		},
		{
			title:"Plazo del seguro",
			more:[
				{
					text:"1 año."
				}
			]
		},
		{
			title:"Suma asegurada",
			more:[
				{
					text:"Desde $562,000 hasta $67,415,000."
				}
			]
		},
		{
			title:"Deducible / Coaseguro",
			more:[
				{
					text:"Aplican de acuerdo al nivel hospitalario de atención médica; adicionalmente sólo el deducible será por año padecimiento."
				}
			]
		},
		{
			title:"Conducto de pago",
			more:[
				{
					text:"Intermediario o Bancario."
				}
			]
		}
	]
};

export const ConnectionSection = {
	title: 'Conexión',

	info:[
		{
			title:"Descripción general",
			description:"Brinda beneficios exclusivos y ventajas como:",
			more:[
				{
					text:"Garantía de contratación de una póliza Individual al salir de una póliza Colectiva."
				},
				{
					text:"Protección en exceso a la Suma Asegurada de la póliza Colecti."
				},
                {
					text:"Posibilidad de eliminar periodos de espera."
				},
                {
					text:"Reconocimiento a la antigüedad del Asegurado al convertir la póliza en individual."
				}
				
			]
		},
		{
			title:"Perfil del cliente",
			more:[
				{
					text:"Edad de contratación desde el nacimiento hasta los 60 años."
				},
				{
					text:"Nivel socioeconómico Cm en adelante."
				}
			]
		},
		{
			title:"Plazo del seguro",
			more:[
				{
					text:"1 año."
				}
			]
		},
		{
			title:"Nacionales Nuevo negocio",
			more:[
				{
					title:"Nuevo Plan Conexión GNP, Suma Asegurada: ",
					text:""
				},
				{
					text:"- Premium/Platino:  146,300,000 pesos."
				},
				{
					text:"- Flexible Índigo, Ámbar y Cuarzo: 91,700,000 pesos."
				},
				{
					text:"- Versátil:  67,415,000 pesos."
				},
				{
					title:"Deducible en exceso, ",
					text:"opciones a elegir: 530,000, 850,000, 1,590,000, 3,180,000, 5,300,000  y 106,000,000 pesos."
				},
				{
					title:"Coaseguro: ",
					text:"Premium/Platino/Flexible 10%, Versátil 15%."
				}
			]
		},
		{
			title:"Renovación",
			more:[
				{
					title:"Conexión Línea Azul, Suma Asegurada: ",
					text:""
				},
				{
					text:"- Plus: Premium/Platino  146,300,000 pesos."
				},
				{
					text:" Flexible Índigo, Ámbar y Cuarzo  91,700,000 pesos"
				},
				{
					text:"- Garantía: Premium/Platino/Flexible  100,000 pesos."
				},
				{
					title:"Deducible en exceso: ",
					text:"Igual a la Suma Asegurada de la Póliza Colectiva."
				},
				{
					title:"Coaseguro: ",
					text:"10%."
				}
			]
		},
		{
			title:"Internacionales, nuevo negocio y renovación",
			more:[
				{
					title:"Conexión Línea Azul, Suma Asegurada: ",
					text:""
				},
				{
					text:"- Plus:           VIP/LAI  5,000,000 o 10,000,000 dls."
				},
				{
					text:"- Garantía:    VIP/LAI 10,000 dls."
				},
				{
					title:"Deducible en exceso: ",
					text:"Igual a la Suma Asegurada de la Póliza Colectiva."
				},
				{
					title:"Coaseguro: ",
					text:"10%."
				}
			]
		},
		{
			title:"Conducto de pago",
			more:[
				{
					text:"Intermediario o Bancario."
				}
			]
		}
	]
};

export const FullnessSection = {
	title: 'Plenitud',

	info:[
		{
			title:"Descripción general",
			description:"Brinda beneficios y ventajas como:",
			more:[
				{
					text:"Producto 100% indemnizatorio."
				},
				{
					text:"Con 3 niveles de Suma Asegurada para contratar."
				},
                {
					text:"Certeza de contar con un monto definido dependiendo de la atención requerida, en caso de: "
				},
                {
					title: "Intervención quirúrgica. ",
					text:"De acuerdo al nivel de complejidad de la cirugía, se dividirá en 4 grupos que otorgan 100%, 75%, 50% o 25% de la Suma Anual de Cirugías contratada."
				},
                {
					title: "Hospitalización. ",
					text:"Otorga un monto por cada día de hospitalización, el cual se duplicará si se tratara de cuidados intensivos, hasta por 30 días continuos y/o 45 días en total por año."
				},
                {
					title: "Tratamiento de una enfermedad grave. ",
					text:"Al diagnóstico de alguna de las 6 enfermedades graves cubiertas por este plan, se otorga en su totalidad la Suma Asegurada correspondiente."
				},
                {
					text:"Cuenta con respaldo de Cuidados en el Hogar, un servicio de asistencias enfocadas en las necesidades de este segmento."
				}
				
			]
		},
		{
			title:"Perfil del cliente",
			more:[
				{
					text:"Edades de contratación desde los 50 años hasta los 70 años*"
				},
				{
					text:"Nivel socioeconómico C+ en adelante."
				},
				{
					text:"*Para negocios con rango de edad entre 65 y 70 años, la contratación estará sujeta a la presentación de estudios médicos."
				}
			]
		},
		{
			title:"Plazo del seguro",
			more:[
				{
					text:"1 año."
				}
			]
		},
		{
			title:"Suma asegurada",
			more:[
				{
					text:"De $100,000 hasta $300,000"
				}
			]
		},
		{
			title:"Deducible / Coaseguro",
			more:[
				{
					text:"No aplica deducible ni coaseguro."
				}
			]
		},
		{
			title:"Conducto de pago",
			more:[
				{
					text:"Intermediario o Bancario."
				}
			]
		}
	]
};

export const AccessSection = {
	title: 'Acceso',

	info:[
		{
			title:"Descripción general",
			description:"Producto exclusivo para servidores públicos que brinda beneficios y ventajas como:",
			more:[
				{
					text:"Suma Asegurada reinstalable por año póliza por Asegurado."
				},
				{
					text:"Acceso a cualquier hospital en México."
				},
                {
					text:"El deducible y coaseguro están definidos de acuerdo al nivel hospitalario que el Asegurado elija al momento de una eventualidad médica."
				}
				
			]
		},
		{
			title:"Perfil del cliente",
			more:[
				{
					text:"Servidores Públicos*."
				},
				{
					text:"Edad de contratación desde el nacimiento hasta los 70 años**"
				},
				{
					text:"* Es indispensable incluir en la solicitud el comprobante laboral que indique que es o fue servidor público (no mayor a 60 días)."
				},
				{
					text:"** Para negocios con rango de edad entre 65 y 70 años, la contratación estará sujeta a la presentación de estudios médicos."
				}
			]
		},
		{
			title:"Plazo del seguro",
			more:[
				{
					text:"1 año."
				}
			]
		},
		{
			title:"Suma asegurada",
			more:[
				{
					title:"Cuatro opciones: ",
					text:"$625,500, $1,250,000, $6,255,000 y $12,510,000."
				}
			]
		},
		{
			title:"Deducible / Coaseguro",
			more:[
				{
					text:"Aplican de acuerdo al nivel hospitalario de atención médica; adicionalmente sólo el deducible será por año padecimiento."
				}
			]
		},
		{
			title:"Conducto de pago",
			more:[
				{
					text:"Tarjeta de Débito, Crédito, Intermediario y Descuento vía Nómina (Dependencias autorizadas)."
				}
			]
		}
	]
};