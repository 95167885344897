import React, {useRef, useEffect, useState, useMemo} from "react";
import styled from 'styled-components';
export const Img = styled.img`
max-width: ${props => props.width || '100%'};
min-height: auto;
color: ${props => props.color || 'black'};
`;
const ServicesMenu = () => {
    const initialValues = {
        home1: false,
        home2: false,
        home3: false,
        home4: false,
        home5: false
    };

    const [sections, setSections] = useState({
        home1: false,
        home2: false,
        home3: false,
        home4: false,
        home5: false
    });

	const hasActive = useMemo(() => {
		for (var [key, value] of Object.entries(sections)) {
			if (value === true)
				return true;
		}
	}, [sections]);

  const updateActiveSection = ({ key, value }) => {
	  
    const newState = Object.assign({}, initialValues, { [key]: sections[key] == true ?  false : value });

    setSections(newState);
  };

	const styleWidth = (section) => {
		if (sections[section] === true) 
			return "active";
		return 'home';

	}
	//hasActive && sections[props.section] !== true ? 'inactive' :
	const SectionContainer = (props) =>
		<div onClick={() => updateActiveSection({ key: props.section, value: true })} className={  hasActive && sections[props.section] !== true ? 'inactive ' + props.section : `${styleWidth(props.section)} `}>

			<div className="container-section">
				{hasActive && sections[props.section] !== true ?
				<label className='vertical'>{props.section}</label>:
				 <Img width={sections[props.section] === false ? "100%" : "20%"} src={props.img}></Img>
					
					
					
				}
				{sections[props.section] === true && <p >{props.section}</p>}
			</div>

		</div>;
 
    return (
        <div className="wrapper" id="Seguros">
			<SectionContainer section='home1' img={"/images/home/home1.jpg"} ></SectionContainer>
			<SectionContainer section='home2' img={"/images/home/home2.jpg"} ></SectionContainer>
			<SectionContainer section='home3' img={"/images/home/home3.jpg"} ></SectionContainer>
			<SectionContainer section='home4' img={"/images/home/home4.jpg"} ></SectionContainer>
			<SectionContainer section='home5' img={"/images/home/home5.jpg"} ></SectionContainer>
        </div>
    );
}
export default ServicesMenu;