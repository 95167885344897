import React from "react";
import '../styles/home.css'
import Carousel from "react-bootstrap/Carousel";
import { Img } from "./productsMenu";

const Home = () => {
    return (
        <div className='container-fluid' id="Inicio">
        <div className="row justify-content-center banners" >
            {/*<Img isBanner
                className="d-block w-100"
                src="/images/Home/Banner_2.png"
                alt="Banner One"
            />
            <div class="hilight">
                <h2>Hockey</h2>
                <p>Sample text</p>
            </div>*/}
            <Carousel className="banner" controls={false} indicators={false} fade>
                <Carousel.Item interval={3000}>
                    <Img
                        className="d-block w-100"
                        src="/images/Home/Banner_2.png"
                        alt="Banner One"
                    />
                </Carousel.Item>
                {/*<Carousel.Item interval={3000}>
                    <Img
                        className="d-block w-100 gray1"
                        src="/images/Home/banner_2.png"
                        alt="Banner One"
                    />
                </Carousel.Item>*/}
            </Carousel>
        </div>
        </div>
    );
}
export default Home;