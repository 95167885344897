export const DanosCategory= {
    name:"Daños",
    sections: [
        {
            name: "Daños",
            products: [
                {name:"Hogar Versátil", id:"HomeSection"},
                {name:"Mi RC", id:"RcSection"}         
            ]
        }
    ]
};

export const HomeSection = {
	title: 'Hogar Versátil',

	info:[
		{
			title:"Descripción general",
			description:"Es un seguro de Casa Habitación que protege y garantiza tu tranquilidad y la de tus seres queridos contra los principales riesgos a los que están expuestos.",
			more:[
			]
		},
		{
			title:"¿Qué ventajas ofrece?",
			more:[
				{
					text:"- Protección del patrimonio y pertenencias; en caso de ser dueño, inquilino o arrendador del inmueble."
				},
				{
					text:"- Propuesta de valuación real y confiable del inmueble."
				},
				{
					text:"- Aseguramiento del inmueble ya sea casa, departamento o condominio horizontal hasta por 30 Millones de pesos incluyendo los cimientos."
				},
				{
					text:"- Protección ante Riesgos Hidrometeorológicos en cualquier parte de la República sin restricción de zona."
				},
				{
					text:"- Descuentos por medidas de seguridad."
				},
				{
					text:"- A Primer Riesgo Absoluto, Pagaremos hasta la suma asegurada sin importar el valor de los bienes."
				},
				{
					text:"- Completamente flexible, pues su protección se puede adaptar al nivel de cobertura que tus clientes necesitan."
				}
			]
		},
		{
			title:"¿Qué cubre?",
			description:"Los gastos generados por cualquier suceso dentro y fuera de tu hogar.",
            more:[
				{
                    title:"- Todo Riesgo Edificio y Contenidos: ",
					text:"En caso de incendio, rayo o explosión."
				},
				{
                    title:"- Responsabilidad Civil: ",
					text:"Daños a terceras personas por parte de cualquier integrante de la familia y empleados domésticos."
				},
				{
                    titel:"- Remoción de Escombros: ",
					text:"Si la vivienda quedó inhabitable, se brinda apoyo para la construcción del hogar con el desmontaje de estructuras frágiles, demolición, limpieza y acarreo de escombros."
				},
				{
                    title:"- Equipo Electrónico y Electrodomésticos: ",
					text:"Reparación o reemplazo de aparatos electrónicos, ante descargas eléctricas, variación de voltaje o corto circuito."
				},
				{
                    title:"- Cristales: ",
					text:"Pago por reemplazo e instalación de cristales rotos."
				},
				{
                    title:"- Robo:"
				},
                {
					text:" - Menaje de Casa: ampara los bienes dentro del hogar."
				},
				{
					text:" - Artículos en Tránsito: protege los bienes fuera del hogar."
				},
				{
					text:" - Objetos de Arte: cubre el robo de objetos de gran valor."
				},
				{
					text:" - Joyas: amparo de joyas mayores $14,000 pesos."
				},
				{
                    title:"- Riesgos Hidrometeorológicos: ",
					text:"En caso de afectaciones causadas por cambios climáticos, como: avalanchas de lodo, granizo, heladas, huracanes, inundaciones, golpe de mar, marejadas, nevadas y vientos tempestuosos, estando en cualquier parte de la República."
				},
				{
                    title:"- Bienes a la Intemperie: ",
					text:"Asegura los bienes expuestos fuera del hogar como son: albercas, decoraciones, instalaciones y/o canchas deportivas, luminarias, muros, palapas, sistemas de riego y paneles solares."
				},
				{
                    title:"- Terremoto / Erupción Volcánica: ",
					text:"Afectaciones que pueden dañar el hogar, muebles, ropa u objetos de valor."
				},
				{
                    title:"- Gastos Extraordinarios: ",
					text:"Causados por algún siniestro en el inmueble."
				},
				{
					text:"Además te proporcionamos asistencias que puedes utilizar todo el año y no sólo en caso de siniestro."
				},
				{
					title:"- Asistencias para el hogar proporciona servicios como:"
				},
				{
					text:"- Plomero."
				},
				{
					text:"- Cerrajero."
				},
				{
					text:"- Electricista."
				},
				{
					text:"- Vidriero."
				},
				{
					text:"- Asesoría legal telefónica (en caso de robo)."
				},
				{
					title:"- Asistencias Médicas para todos los integrantes de la familia con:"
				},
				{
					text:"- Asistencia médica telefónica."
				},
				{
					text:"- Consulta médica a domicilio."
				},
				{
					text:"- Servicio de ambulancia."
				},
				{
					title:"- Protección para todo tipo de mascotas con servicios de:"
				},
				{
					text:"- Asistencia veterinaria telefónica."
				},
				{
					text:"- Apoyo en caso de extravío."
				},
				{
					text:"- Para mayor protección, te sugerimos contratar los servicios funerarios para todo tipo de mascotas."
				}
			]
		}
	]
};

export const RcSection = {
	title: 'Mi RC',

	info:[
		{
			title:"¿Qué es?",
			description:"Mi RC GNP es un seguro diseñado para aquellas personas que se preocupan por su entorno y por quienes los rodean, respondiendo en caso de daños materiales, lesiones corporales y fallecimiento ocasionados a terceros por él, sus familiares directos, mascotas y empleados.",
			more:[
			]
		},
		{
			title:"¿Qué ventajas me ofrece?",
			more:[
				{
					text:"Sumas Aseguradas y primas fijas."
				},
				{
					text:"Facilidad de contratación, ¡sin requisitos de suscripción!."
				},
				{
					text:"Cobertura por la Responsabilidad Civil del inmueble, vehículos y mascotas en una sola Póliza."
				},
				{
					text:"Pago de fianzas y honorarios para defensa legal."
				}
			]
		},
		{
			title:"¿Qué cubre?",
			description:"La Responsabilidad Civil personal y de familiares directos por:",
            more:[
				{
					text:"La realización de cualquier tipo de actividades privadas y familiares."
				},
				{
					text:"Daños a inmuebles de terceros sin importar si son dueños o arrendatarios."
				},
				{
					text:"Lesiones que ocasionen perros o gatos a terceros, incluyendo otros animales domésticos."
				},
				{
					text:"Daños durante viajes de estudio o vacacionales a nivel nacional e internacional."
				},
				{
					text:"Afectaciones causadas a terceros por los empleados domésticos o los daños que se ocasionen en su persona."
				},
				{
                    text:"Percances ocasionados por conducir automóviles propios o ajenos."
				},
                {
					text:"Costos y honorarios legales que deba cubrir el Asegurado para su defensa."
				}
			]
		}
	]
};

