import React, { } from 'react'
import { FaFacebookSquare, FaWhatsappSquare, FaTwitterSquare, FaMapMarkerAlt, FaCalendarAlt, FaPhoneAlt, FaPhoneSquareAlt } from "react-icons/fa";
import { GiRotaryPhone } from 'react-icons/gi'

const Footer = () => {
    return(
        <div className="footer">
            <div>
                <img className='img-footer' src="/images/Home/LogoPS.png"></img>
            </div>
            <div className='mt-auto mb-0'>
                <p className='text-center'>Horarios</p>
                <p className='text-center'> 8:00 AM - 6:00 PM Lunes a Viernes</p>
                <p className='text-center'><FaMapMarkerAlt className="icons-footer-marker" /> Salvador Creel #56 Col. Ampl. Las Margaritas Torreón Coahuila, México.</p>
            </div>
            <div className='text-center-footer'>
               <p><FaPhoneSquareAlt className="icons-footer-phone" /> (871) 680 99 06</p>
                <p>Siguenos en Redes</p>
                <FaFacebookSquare className="icons-footer" />
                <FaWhatsappSquare className="icons-footer" />
                <FaTwitterSquare className="icons-footer" />
            </div>
        </div>
    )
}

export default Footer;