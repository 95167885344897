import React, { } from 'react'
import Home from '../components/Home.js'
import ServicesMenu from '../components/ServicesMenu.js'
import ProductsMenu from './productsMenu.js'
import AboutUs from './Us.js'
import ContactUs from './ContactUs.js';
import MapContainer from './MapContainer.js'


const Container = () => {
    return(
        <div className='main-container'>
            <Home/>
            <ProductsMenu/>
            {/* <ServicesMenu/> */}
            <AboutUs/>
            <ContactUs/>
            <MapContainer/>
        </div>
    )
}

export default Container;