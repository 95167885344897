import React, { Fragment, useState } from 'react'
import { Modal } from 'react-bootstrap'
import '../styles/contactUs.css'
import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from "yup";
import emailjs from '@emailjs/browser';


function ContactUs() {
    const [showModal, setShowModal] = useState(false)

    const handleSubmit = (values, actions) => {
        emailjs.init("aZHFcueRWrOUmyEtA")
        emailjs.send("service_jvlg9jp", "template_0boiys7", {
            name: values.name,
            phone: values.phone,
            email: values.email,
            checked: values.checked,
            comments: values.comments,
        });
        actions.resetForm();
        setShowModal(true);
    }
    const SignupSchema = Yup.object().shape({
        name: Yup.string()
            .required('El campo nombre es requerido.'),
        phone: Yup.string(),
        email: Yup.string()
            .required("El campo correo es requerido.")
            .email('Ingresa un correo electrónico válido'),
        comments: Yup.string().required('Ingresa un comentario')
    });

    const TextAreaComponent = (props) => (
        <textarea {...props} type="text" className="form-control" />

    );

    return (
        <Fragment>
            <div className="conta" id="Contacto">
                <div className="subtitle-contact text-center">
                    <h1>Protege tu patrimonio, contáctanos!</h1>
                </div>
                <div className="container form-main">
                    <Formik
                        initialValues={{
                            name: "",
                            phone: "",
                            email: "",
                            checked: [],
                            comments: "",
                        }}
                        onSubmit={(values, actions) => handleSubmit(values, actions)}
                        validationSchema={SignupSchema}
                    >
                        {({ errors, touched }) => (
                            <Form>
                                <Field id="name" name="name" type="text" placeholder="Nombre" className="form-control" />
                                <ErrorMessage name="name">{msg => <label className='text-error' htmlFor="inputAddress">{msg}</label>}</ErrorMessage>

                                <Field maxLength={15} id="phone" name="phone" type="text" placeholder="Teléfono" className="form-control" />

                                <Field id="email" name="email" type="email" placeholder="Correo" className="form-control" />
                                <ErrorMessage name="email">{msg => <label className='text-error' >{msg}</label>}</ErrorMessage>
                                
                                <div id="checkbox-group" className="text-content">Me interesa:</div>
                                <div role="group" aria-labelledby="checkbox-group" className="text-content checks">
                                    <label>
                                        <Field type="checkbox" className="checkbox" name="checked" value="Gasto Medico" />
                                        Gasto Médico
                                    </label>
                                    <label>
                                        <Field type="checkbox" className="checkbox" name="checked" value="Vida" />
                                        Vida
                                    </label>
                                    <label>
                                        <Field type="checkbox" className="checkbox" name="checked" value="GMM" />
                                        GMM
                                    </label>
                                    <label>
                                        <Field type="checkbox" className="checkbox" name="checked" value="Autos" />
                                        Autos
                                    </label>
                                    <label>
                                        <Field type="checkbox" className="checkbox" name="checked" value="Daños" />
                                        Daños
                                    </label>
                                </div>
                                <div role="group" aria-labelledby="checkbox-group" className="text-content checks-mobile">
                                    <div>
                                        <label>
                                            <Field type="checkbox" className="checkbox" name="checked" value="Gasto Medico" />
                                            Gasto Médico
                                        </label>
                                        <label>
                                            <Field type="checkbox" className="checkbox" name="checked" value="Vida" />
                                            Vida
                                        </label>
                                        <label>
                                            <Field type="checkbox" className="checkbox" name="checked" value="GMM" />
                                            GMM
                                        </label>
                                    </div>
                                    <div>
                                        <label>
                                            <Field type="checkbox" className="checkbox" name="checked" value="Autos" />
                                            Autos
                                        </label>
                                        <label>
                                            <Field type="checkbox" className="checkbox" name="checked" value="Daños" />
                                            Daños
                                        </label>
                                    </div>
                                </div>
                                <Field name="comments" placeholder="Elige tu producto o déjanos tus comentarios..." as={TextAreaComponent} />
                                <ErrorMessage name="comments">{msg => <label className='text-error'>{msg}</label>}</ErrorMessage>
                                <div className="mt-4 text-center">
                                    <button type="submit" className="btn btn-confirm">Estoy Interesado</button>
                                </div>
                            </Form>
                        )}
                    </Formik>
                </div>
            </div>

            <Modal
                size="sm"
                centered
                show={showModal}
                onHide={() => setShowModal(false)}
                className='text-center'
            >
                <Modal.Header closeButton>
                    <Modal.Title id="title-sm">
                        Mensaje enviado
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div>
                        <p>Hemos recibido tu mensaje, muy pronto nos pondremos en contacto contigo.</p>
                    </div>
                </Modal.Body>
            </Modal>

        </Fragment>

    );
}

export default ContactUs