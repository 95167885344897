import logo from './logo.jpg';
import './App.css';
import Header from './components/header';
import Home from './components/Home'
import Container from './components/container';
import Footer from './components/footer';
import './styles/container.css';
import './styles/header.css';
import './styles/footer.css';
import './styles/navbar.css';
import "./styles/productsMenu.css";

function App() {
  return (
    <div className="main-app p-0  container-fluid">
      <Header/>
      <Container/>
      <Footer /> 
      {/* <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p>
          Estamos en construcción!
        </p>
      </header> */}
    </div>
  );
}

export default App;

