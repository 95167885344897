import React, { Component } from "react";
import { ImLocation } from "react-icons/im";
import "../styles/mapContainer.css";
import { GoogleApiWrapper, Map, InfoWindow, Marker } from 'google-maps-react';

export class MapContainer extends Component {

    static defaultProps = {
        center: {
            lat: 25.5640244,
            lng: -103.4433151
        },
        zoom: 15,
    };
    render() {
        const containerStyle = {
            position: 'relative',  
            width: '100%',
            height: '100%'
          }
        return (
            <div className="map-container">
                <Map
                    containerStyle={containerStyle}
                    google={this.props.google}
                    zoom={this.props.zoom}
                    initialCenter={{
                        lat: this.props.center.lat,
                        lng: this.props.center.lng
                    }}>
                    <Marker
                        name={'Dolores park'}
                        position={{lat: this.props.center.lat, lng: this.props.center.lng}} />
                    <Marker />
                    <Marker
                        name={'Hedesa'}
                        position={{
                            lat: this.props.center.lat,
                            lng: this.props.center.lng
                        }} />
                    <Marker />
                    <InfoWindow>
                        <div>
                            <p>Click on the map or drag the marker to select location where the incident occurred</p>
                        </div>
                    </InfoWindow>
                </Map>
            </div>
        )
    }
}

export default GoogleApiWrapper({
    // apiKey: ('AIzaSyApHBzojbqc9llt8AClLcX28SzQFNmeUrU')
    apiKey: ('AIzaSyCXfHxVcHPXR5r-2_1QqJb6gg8WPXR9jho') 
})(MapContainer)