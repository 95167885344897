import React from "react";
import '../styles/us.css';
import { IoIosContacts } from 'react-icons/io';
import { AiOutlineSafetyCertificate } from 'react-icons/ai';
import { GiReceiveMoney } from 'react-icons/gi';


const Us = () => {
    return (
        <div className="us-container" id="Nosotros">
            <div className="text">
                <h1>¿Por qué Nosotros?</h1>
            </div>
            <div className="container content content-text">
                <div className="content-inf">
                    <div className="icons-pos">
                        <IoIosContacts className="icons"/>
                    </div>
                    <div className="info">
                        <b>
                            PuntoSeguro nace con la finalidad de proporcionar estrategias y herramientas útiles a las familias, 
                            personas y empresas para el logro de sueños financieros y de seguridad.
                        </b>
                    </div>
                </div>
                <div className="content-inf">
                    <div className="icons-pos">
                        <AiOutlineSafetyCertificate className="icons"/>
                    </div>
                    <div  className="info">
                        <b>
                            La experiencia, el conocimiento y la innovación nos respaldan, cada vez son más los clientes que depositan 
                            su confianza en nosotros, permitiendo que seamos participes de la cristalización de sus metas, a través de nuestra asesoría y productos.
                        </b>
                    </div>
                    <div>
                    </div>
                </div>
                <div className="content-inf">
                    <div className="icons-pos">
                        <GiReceiveMoney className="icons"/>
                    </div>
                    <div  className="info">
                        <b>
                            Nuestro objetivo siempre será proteger el patrimonio de cada uno de nuestros clientes, cuidando su salud, finanzas, Inversiones y dinero, 
                            así como diseñar las mejores estrategias en ahorro de impuestos, inversiones y retiro.
                        </b>
                    </div>
                </div>
            </div>
        </div>
    );
}
export default Us;