import React, { useState, useMemo, Fragment } from "react";
import styled from 'styled-components';
import { FaPlus, FaMinus } from 'react-icons/fa';
/*import { VidaCategory, DotalSection } from "../data/vidaData";*/
import { Modal } from "react-bootstrap";
import * as ProductsList from '../data/vidaData';
import * as GMMList from '../data/GMMData';
import * as AutosList from '../data/AutosData';
import * as DanosList from '../data/DanosData';

export const Img = styled.img`
max-width: ${props => props.width || '100%'};
min-height: auto;
color: ${props => props.color || 'black'};
&:hover  {
    cursor: ${props => props.isBanner ? '' :'pointer' } ;
	opacity:${props => props.isBanner ? 1 :.7 } ; ;
  }
`;
const ProductsMenu = () => {
	const [showModal, setShowModal] = useState({showModal:false, data:{}});

	const initialValues = {
		home1: false,
		home2: false,
		home3: false,
		home4: false,
		home5: false
	};

	const [sections, setSections] = useState({
		home1: false,
		home2: false,
		home3: false,
		home4: false,
		home5: false
	});

	const hasActive = useMemo(() => {
		for (var [key, value] of Object.entries(sections)) {
			if (value === true)
				return true;
		}
	}, [sections]);

	const updateActiveSection = ({ key, value }, mobile = false) => {
		const newState = Object.assign({}, !mobile ? initialValues : sections, { [key]: sections[key] === true ? false : value });

		setSections(newState);
	};

	const styleWidth = (section) => {
		if (sections[section] === true)
			return "active";
		return 'home';

	}
	//hasActive && sections[props.section] !== true ? 'inactive' :
	const SectionContainer = (props ) =>{
		return <div className={hasActive && sections[props.section] !== true ? 'inactive ' + props.section : `${styleWidth(props.section)} `}>

			<div className="container-section">
				{hasActive && sections[props.section] !== true ?
					 <div onClick={() => updateActiveSection({ key: props.section, value: true })} style={ {width:'100%'} }>
						<label className='vertical'>{props.title}</label>
					</div>
					:
					<Fragment>
						<p className={sections[props.section] === false ? "title-section" : "title-section-active"}>{props.title}</p>
						<Img cursor={true} onClick={() => updateActiveSection({ key: props.section, value: true })}  width={sections[props.section] === false ? "100%" : "20%"} src={props.img}>
							
						</Img>
						{sections[props.section] && props.children}
					</Fragment>
				}
				{/* {sections[props.section] === true && <p >{props.section}</p>} */}
			</div>

		</div>
	};


	const Section = (props) => {
		return (
			<div className="col-9 list-service">
				<ul>
					{/* <h4>{props.data.title}</h4> */}
					{
						props.data.info.map((item, index) => {
							const { title, description, more } = item
							return (
								<li className="profesional-section">
									{title}
									{description &&
										<p className="mb-2">{description}</p>}
									{more.map((moreInfo, indexj) => {console.log(moreInfo)
										const { title, text, table } = moreInfo;
										return (
											<Fragment>
												<p>{title && <span>{title}</span>}{text}</p>
												{table &&
													<table className="table-section">
														<tr className="w-100 text-center principal-header"><th colSpan={4}>{table.title}</th></tr>
														<tr>{table.columns.map((col, indexcol) => {
															return <th className="header-table">{col}</th>
														})}

														</tr>
														{table.values.map((val, indexval) => {
															return <tr className="info">
																<td>{val.cobertura}</td>
																<td>{val.suma}</td>
																<td>{val.deducible}</td>
																<td>{val.evento}</td>
																</tr>
														})}

														
													</table>
												}
											</Fragment>
										)
									})}</li>
							)
						})}
				</ul>
			</div>
		)
	}


const SectionMain = props => {
	const {data, hasOnlyTitles, productList } = props;
	return (
		<div className="section-container-vida">
			<div className="products-container row">
				{data &&
					data.sections.map((item, indexi) => {
						return (
							<div key={'section' + indexi} className="col-md-4 col-12 list-service">
								<ul>
									<h4 className={`mobile-font ${hasOnlyTitles && indexi > 0 ? 'hide-title': ''}`}>{item.name}</h4>
									{item.products.map((product, indexj)=>{console.log(product.id)
										return(
											<li key={'prod' + indexj}><a onClick={() => setShowModal({show:true, data:productList[product.id]})}>{product.name}</a></li>
										)
									})}
								</ul>
							</div>
						)
					})}
			</div>
		</div>
	)
}

const SectionGMM = props => {
	return (
		<div className="section-container-GMM">
			<div className="products-container row">
				{GMMList.GMMCategory &&
					GMMList.GMMCategory.sections.map((item, indexi) => {
						return (
							<div key={'section' + indexi} className="col-12 list-service">
								<ul>
									<h4 className="mobile-font">{item.name}</h4>
									{item.products.map((product, indexj)=>{
										return(
											<li key={'prod' + indexj}><a onClick={() => setShowModal({show:true, data:GMMList[product.id]})}>{product.name}</a></li>
										)
									})}
								</ul>
							</div>
						)
					})}
			</div>
		</div>
	)
}

const SectionDanos = props => {
	return (
		<div className="section-container-danos">
			<div className="products-container row">
				{DanosList.DanosCategory &&
					DanosList.DanosCategory.sections.map((item, indexi) => {
						return (
							<div key={'section' + indexi} className="col-12 list-service">
								<ul>
									<h4 className="mobile-font">{item.name}</h4>
									{item.products.map((product, indexj)=>{
										return(
											<li key={'prod' + indexj}><a onClick={() => setShowModal({show:true, data:DanosList[product.id]})}>{product.name}</a></li>
										)
									})}
								</ul>
							</div>
						)
					})}
			</div>
		</div>
	)
}

const SectionAutos = props => {
	return (
		<div className="section-container-danos">
			<div className="products-container row">
				{AutosList.AutosCategory &&
					AutosList.AutosCategory.sections.map((item, indexi) => {
						return (
							<div key={'section' + indexi} className="col-12 list-service">
								<ul>
									<h4 className="mobile-font">{item.name}</h4>
									{item.products.map((product, indexj)=>{
										return(
											<li key={'prod' + indexj}><a onClick={() => setShowModal({show:true, data:AutosList[product.id]})}>{product.name}</a></li>
										)
									})}
								</ul>
							</div>
						)
					})}
			</div>
		</div>
	)
}

	return (
		<Fragment>
			<div className="wrapper" id="Inversiones">
				<SectionContainer title="Vida" section='home1' img={"/images/AboutUs/Vida2.png"} imgInactive={"/images/AboutUs/mascota-inactive.jpg"} >
					<SectionMain 
					data={ProductsList.VidaCategory}
					productList={ProductsList}
					/>
				</SectionContainer>
				
				<SectionContainer title="Gastos Médicos" section='home2' img={"/images/AboutUs/GMM.png"} imgInactive={"/images/AboutUs/mascota-inactive.jpg"} >
				<SectionMain 
					data={GMMList.GMMCategory}
					productList={GMMList}
					hasOnlyTitles
					/>
				</SectionContainer>

				<SectionContainer title="Autos" section='home3' img={"/images/AboutUs/Car.png"} imgInactive={"/images/AboutUs/vida-educacion-retiro-y-ahorro@md1-inactive.png"} >
					<SectionMain 
					data={AutosList.AutosCategory}
					productList={AutosList}
					hasOnlyTitles
					/>
				</SectionContainer>
				
				<SectionContainer title="Daños" section='home4' img={"/images/AboutUs/Danos.png"} imgInactive={"/images/AboutUs/mascota-inactive.jpg"} >
					<SectionDanos></SectionDanos>
				</SectionContainer>
				
        	</div>
			<div className="container-section-mobile" id="Inversiones">
				<p className="text-center title-mobile">Servicios</p>
				<div className="container-mobile">
					<div className="section-header" onClick={() => updateActiveSection({ key: 'home1', value: !sections['home1'] }, true)}>
						<p key={'h1'} > Vida </p>
						{sections['home1'] ? <FaMinus className="icon-mobile" /> : <FaPlus className="icon-mobile" /> }
					</div>
					{sections['home1'] && <div className="section-info">
						<SectionMain></SectionMain>
					</div>}
				</div>

				<div className="container-mobile">
					<div className="section-header" onClick={() => updateActiveSection({ key: 'home2', value: !sections['home2'] }, true)}>
						<p key={'h2'} > Gastos Médicos </p>
						{sections['home2'] ? <FaMinus className="icon-mobile" /> : <FaPlus className="icon-mobile" />}
					</div>
					{sections['home2'] && <div className="section-info">
						<SectionGMM></SectionGMM>
					</div>}
				</div>
				<div className="container-mobile">
					<div className="section-header" onClick={() => updateActiveSection({ key: 'home3', value: !sections['home3'] }, true)}>
						<p key={'h3'} > Autos </p>
						{sections['home3'] ? <FaMinus className="icon-mobile" /> : <FaPlus className="icon-mobile" />}
					</div>
					{sections['home3'] && <div className="section-info">
					<SectionAutos></SectionAutos>
					</div>}
				</div>

				<div className="container-mobile">
					<div className="section-header" onClick={() => updateActiveSection({ key: 'home4', value: !sections['home4'] }, true)}>
						<p key={'h4'} > Daños </p>
						{sections['home4'] ? <FaMinus className="icon-mobile" /> : <FaPlus className="icon-mobile" />}
					</div>
					{sections['home4'] && <div className="section-info">
						<SectionDanos></SectionDanos>
					</div>}
				</div>

			</div>
			<Modal
                size="lg"
                centered
                show={showModal.show}
                onHide={() => setShowModal({...showModal,show:false})}
            >
                <Modal.Header closeButton>
                    <Modal.Title id="title-sm">
						{showModal.data.title}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Section data={ showModal.data }></Section>
                </Modal.Body>
			</Modal>
		</Fragment>
	);
}
export default ProductsMenu;