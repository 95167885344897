export const AutosCategory= {
    name:"Autos",
    sections: [
        {
            name: "Autos y Motos",
            products: [
                {name:"Seguro de Autos", id:"CarsSection"},
                {name:"Auto Elite", id:"EliteSection"},
                {name:"Seguro de Motos", id:"MotorSection"},
                {name:"Autos Turistas GNP", id:"TouristSection"},
                {name:"Micronegocio", id:"MicroSection"}
            ]
        }
    ]
};

export const CarsSection = {
	title: 'Seguro de Autos',

	info:[
		{
			description:"¡Protégelos! Ahora podrás ofrecer a tus clientes un seguro integral que se adapta a las necesidades del mercado ¡Nosotros los respaldamos!",
			more:[
			]
		},
		{
			title:"¿Qué es?",
			more:[
				{
					text:"El Seguro de Autos individual es la protección para cualquier tipo de vehículo, ya que reconocemos sus características y las del conductor habitual. Ahora podrás ofrecer a tus clientes un seguro integral que se adapta a las necesidades del mercado."
				}
			]
		},
        {
			title:"¿Qué ventajas me ofrece?",
			more:[
				{
					text:"Aseguramos todo tipo de vehículos."
				},
                {
					text:"Contamos con distintos tipos de indemnización."
				},
                {
					text:"Complementamos tu protección con una amplia variedad de coberturas opcionales."
				},
                {
					text:"Paga tu Seguro a meses sin intereses."
				},
                {
					text:"Al contratar tu Seguro de Auto GNP, obtén tu Seguro Obligatorio de Responsabilidad Civil por Daños a Terceros sin costo adicional."
				},
				{
					text:"Cuentas con CLUB GNP… Nuestro servicio de Asistencias está disponible las 24 horas durante los 365 días del año."
				}
			]
		},
        {
			title:"Perfil del cliente",
			more:[
				{
					text:"Persona física y moral"
				}
			]
		},
		{
			title:"Perfil del vehículo",
			more:[
				{
					text:"Autos y Pick Ups hasta 3.5 toneladas de uso particular."
				},
				{
					title:"Procedencia:"
				},
                {
                    text:"Residentes."
                },
				{
                    text:"Blindados."
                },
				{
                    text:"Fronterizos."
                },
				{
                    text:"Legalizados."
                },
				{
                    text:"Importados."
                },
				{
                    text:"Antiguos."
                },
				{
                    text:"Clásicos."
                },
				{
                    text:"Con valor mayor a 2.5 MDP"
                }
			]
		},
		{
			title:"¿Qué ventajas me ofrece?",
			more:[
				{
					title:"Dependiendo del paquete que contrates, cuentas con las siguientes coberturas:"
				},
                {
					text:"En caso de robo, indemnizamos a Valor Comercial, Valor Convenido o Valor Factura."
				},
                {
					text:"Cubrimos los daños causados a terceros."
				},
                {
					text:"Ambulancia, hospitalización y más, con la cobertura de Gastos Médicos."
				},
                {
					text:"Te cubre los daños que sufra tu persona tras un accidente."
				},
				{
					text:"Proporcionamos un abogado en caso de necesitarlo por siniestro con protección legal."
				},
				{
					title:"Aumenta la protección con las coberturas opcionales:"
				},
				{
					text:"Robo Parcial cubre la reposición de autopartes instaladas de fábrica a consecuencia de un robo y/o asalto con violencia o sin violencia. Con Robo Parcial Plus reponemos las llantas y rines de tu vehículo."
				},
				{
					text:"Con Cero Deducible en Pérdida Total en el siniestro es posible que el deducible sea 0 y recibe la indemnización completa."
				},
				{
					text:"Daños Materiales de Llantas y/o Rines Te ayuda a pagar la reposición de las llantas y/o rines, en caso de accidente o caída en un bache."
				},
				{
					text:"Reposición de Llaves apoya económicamente a nuestros asegurados en caso de pérdida o daños en llaves."
				},
				{
					text:"Garantía de Autos Financiados cubrimos la deuda pendiente de pago con la financiera en caso de contar con un crédito o arrendamiento vigente en caso de siniestro por Daños Materiales Pérdida Total o Robo Total.."
				},
				{
					text:"Con Ayuda para Pérdidas Totales se indemniza con tu suma asegurada en caso de pérdida total."
				},
				{
					text:"RC Ocupantes ampara los daños o muerte provocados a otras personas que viajan dentro del vehículo."
				},
				{
					text:"Con Auto Sustituto se brinda un vehículo en caso de que el asegurado haya sufrido un siniestro."
				},
				{
					text:"Accidentes Automovilísticos al Conductor cubre el fallecimiento o alguna pérdida orgánica del conductor en caso de accidentes y siempre y cuando esté conduciendo."
				},
				{
					text:"Conductor Protegido cubre los Gastos Médicos y asistencia vial al conductor cuando maneja un vehículo distinto al asegurado."
				},
				{
					text:"Cubrimos las Adaptaciones, Conversiones o Equipo Especial que incluya tu Auto."
				},
				{
					text:"Responsabilidad Civil Fallecimiento te protege en caso de que resultes responsable de un siniestro a causa de accidente."
				},
				{
					title:"Requisitos de Contratación:"
				},
				{
					text:"Licencia para conducir vigente."
				},
				{
					text:"Contar con una Auto o Pick Up nacional o importada por la armadora."
				},
				{
					text:"El contratante deberá ser persona física."
				},
				{
					text:"Nombre del contratante, Registro Federal de Contribuyentes (RFC), fecha de nacimiento, edad y género del conductor principal."
				},
				{
					text:"Año, modelo, marca, versión y número de serie o motor."
				},
				{
					title:"Restricciones de Contratación:"
				},
				{
					title:"No aplica para:"
				},
				{
					text:"Persona moral."
				},
				{
					text:"Flotillas"
				},
				{
					text:"RC Estatal"
				},
				{
					text:"Seguro que brinda protección para Autos y Pick ups, cumpliendo con los lineamientos de cada estado."
				}
			]
		},
	]
};


export const EliteSection = {
	title: 'Auto Elite',

	info:[
		{
			title:"¿Qué es?",
			more:[
				{
					text:"Es un complemento a tu protección que brinda mayor seguridad y confianza en tu camino sin importar el origen de tu póliza amplia."
				}
			]
		},
        {
			title:"¿Qué ventajas me ofrece?",
			more:[
				{
					title:"Conoce los beneficios de ser Auto Elite:​"
				},
                {
					title:"Cero Deducible en Pérdida Total"
				},
                {
					text:"Sin gastos innecesarios. Despreocúpate de pagar más al momento del siniestro ya que cuentas con deducible cero en caso de pérdida total por daños materiales y robo."
				},
                {
					title:"Daños Materiales de Llantas y/o Rines"
				},
                {
					text:"Que nada te detenga. Si a causa de un accidente o caída en un bache las llantas o rines de tu auto sufren daños, te ayudamos a pagar reposición."
				},
				{
					title:"Reposición de Llaves"
				},
				{
					text:"Las llaves siempre a la mano. Te reembolsamos el costo si perdiste o te robaron tus llaves. Si presentas acta de denuncia por robo o extravío ¡te ahorras el deducible!"
				},
				{
					title:"Auto Sustituto"
				},
				{
					text:"Para que nada te detenga. Te proporcionamos un auto sustituto por 10 días en caso de pérdida total."
				},
				{
					text:"Disponible solo para clientes con paquete Amplia en otras compañías."
				}
			]
		},
        {
			title:"¿Qué cubre?",
			more:[
				{
					text:"Auto Elite es la cobertura opcional que te permite cuidar de tu auto sin afectar tu bolsillo."
				},
				{
					title:"Conoce las sumas aseguradas y deducibles de Auto Elite:",
					table:{
						title:"Sumas Aseguradas y Deducibles",
						columns:["Cobertura", "Suma Asegurada", "Deducible","Numbero de Eventos"],
						values:[
							{cobertura:"Cero deducible en Pérdida Total", deducible:"Correspondiente a Daños materiales Pérdida Total", suma:"Amparada", evento:"1"},
							{cobertura:"Auto Sustituto", suma:"10 días",deducible:"Sin deducible", evento:"1"},
							{cobertura:"Reposición de llaves", suma:"5,000", deducible:"20%", evento:"1"}
					]
				}
						
				},
				{
					title:"Restricciones de Contratación:"
				},
				{
					title:"No aplica para:"
				},
                {
                    text:"Autos con antigüedad mayor a 5 años."
                },
                {
                    text:"Autos con paquetes RC y Limitada."
                },
                {
                    text:"Vehículos antiguos, clásicos, legalizados y fronterizos."
                },
                {
                    text:"Camiones de más de 3,5 toneladas, tractocamiones, autobuses, remolques y de renta diaria."
                },
                {
                    text:"Transporte privado, público de carga y federal de carga."
                },
                {
                    text:"Flotillas."
                }
			]
		},
		
	]
};

export const MotorSection = {
	title: 'Seguro de Motos',

	info:[
		
		{
			description:"En GNP sabemos que tu motocicleta es importante y lo mucho que te interesa cuidarla, por ello , te ofrecemos un seguro que te protege con el respaldo que nos caracteriza",
			title:"¿Qué es?",
			more:[
				{
					text:"Reconocemos que cada moto es diferente. Sea pequeña, mediana o grande, contamos con Coberturas que puedes adaptar a tus necesidades. Hay un plan de acuerdo a las características de tu motocicleta."
				}
			]
		},
        {
			title:"¿Qué ventajas me ofrece?",
			more:[
                {
					text:"Aseguramos hasta 50 marcas de motocicletas."
				},
                {
					text:"Disponible para motocicletas con hasta 15 años de antigüedad."
				},
				{
					text:"Indemnización a Valor Comercial."
				},
				{
					text:"Complementamos tu protección cubriendo las Adaptaciones, Conversiones o Equipo."
				},
				{
					text:"Especial de tu motocicleta."
				},
				{
					text:"Paga tu Seguro a 3 o 6 meses sin intereses."
				},
				{
					text:"Al contratar tu Seguro de Moto GNP, obtén tu Seguro Obligatorio de Responsabilidad Civil por Daños a Terceros sin costo adicional."
				}
			]
		},
        {
			title:"¿Qué cubre?",
			more:[
				{
					title:"Dependiendo del paquete que contrates, cuentas con las siguientes Coberturas:"
				},
				{
					text:"Los Daños Materiales que sufra tu moto, ya sea parcial o total."
				},
				{
					text:"En caso de robo, indemnizamos a Valor Comercial."
				},
                {
                    text:"Cubrimos los daños causados a terceros al conducir la moto."
                },
                {
                    text:"Ambulancia, hospitalización y más; con la cobertura de Gastos Médicos."
                },
                {
                    text:"Te cubre los daños que sufra tu persona tras un accidente."
                },
                {
                    title:"Aumenta la protección con las coberturas opcionales:"
                },
                {
                    text:"Cubrimos las Adaptaciones, Conversiones o Equipo Especial que incluyas a tu motocicleta."
                },
                {
                    text:"Responsabilidad Civil Fallecimiento te protege en caso de que resultes responsable de un siniestro a causa de accidente."
                },
				{
                    title:"Requisitos de Contratación:"
                },
				{
                    text:"Licencia para conducir vigente."
                },
				{
                    text:"Contar con una motocicleta nacional o importada por la armadora."
                },
				{
                    text:"El contratante deberá ser persona física."
                },
				{
                    text:"Nombre del contratante, Registro Federal de Contribuyentes (RFC), fecha de nacimiento, edad y género del conductor principal."
                },
				{
                    text:"Año, modelo, marca, versión y número de serie o motor."
                },
				{
                    title:"Restricciones de Contratación:"
                },
				{
                    title:"No aplica para:"
                },
				{
                    text:"Persona moral."
                },
				{
                    text:"Uso comercial de la motocicleta."
                },
				{
                    text:"Flotillas"
                }

			]
		},
		
	]
};

export const TouristSection = {
	title: 'Autos Turistas GNP',

	info:[
		{
			description:"Viajar desde el extranjero a México en auto es una de las mejores formas de conocer nuestro país. Es por ello que contamos con un Seguro que protege su auto durante su estancia, sin importar si se trata de un viaje de trabajo o placer.",
			more:[
			]
		},
		{
			title:"¿Qué es?",
			more:[
				{
					text:"Autos Turistas GNP es un Seguro para vehículos extranjeros que vienen de visita a México y que los resguarda durante su estancia."
				}
			]
		},
        {
			title:"¿Qué ventajas me ofrece?",
			more:[
				{
					text:"Protección personalizada de acuerdo a los días de estancia, desde 1 día hasta 1 año."
				},
                {
					text:"Indemnización a Valor Comercial vigente al momento del accidente."
				},
                {
					text:"Extiende tu protección con las Coberturas de Riesgos Adicionales."
				},
                {
					text:"Opciones de pago: Tarjeta de crédito o débito."
				},
                {
					text:"Atención telefónica bilingüe los 365 días del año."
				}
			]
		},
        {
			title:"¿Qué cubre?",
            description:"De acuerdo a la protección contratada el Seguro ofrece las siguientes Coberturas:",
			more:[
				{
					text:"Colisiones, vuelcos y rotura de cristales causados por accidentes viales."
				},
                {
					text:"Pago de daños por Robo Total."
				},
                {
					text:"Responsabilidad Civil Daños a Terceros para accidentes con otros conductores involucrados."
				},
                {
					text:"Gastos Médicos para el conductor o sus acompañantes."
				},
                {
					text:"Fianza Garantizada y Asistencia Legal en caso de accidentes con repercusiones legales."
				},
                {
					text:"Servicios de Asistencia en viajes con servicio de grúa, renta de vehículo por reparación, así como información turística y administrativa."
				}
			]
		},
		{
			title:"Requisitos de Contratación",
			more:[
				{
					text:"Automóvil, pick up hasta 3.5 toneladas o casa rodante (de uso particular)."
				},
                {
                    text:"Licencia de conducir vigente del estado donde reside el contratante."
                },
                {
                    text:"Persona Física o Moral."
                }
			]
		},
		{
			title:"Restricciones de Contratación",
            description:"No aplica para: ",
			more:[
				{
					text:"Motocicletas, camiones de más de 3,5 toneladas, tractocamiones, autobuses, remolques y de renta diaria."
				},
                {
                    text:"Flotillas Comerciales."
                }
			]
		}
	]
};

export const MicroSection = {
	title: 'Micronegocio',

	info:[
		{
			title:"Descripción general",
			description:"GNP cuenta con un producto que te permite ofrecer un Seguro Integral de Autos, elaboramos paquetes y coberturas adaptables a las necesidades de cada empresa, sus vehículos y usos, con Micronegocio podrás contratar Pólizas individuales hasta 5 unidades para usos comerciales.",
			more:[
			]
		},
		{
			title:"¿Qué cubre?",
			more:[
				{
					text:"Daños Materiales."
				},
				{
					text:"Robo de las unidades."
				},
				{
					text:"Gastos médicos."
				},
				{
					text:"Protección Legal."
				},
				{
					text:"Asistencia vial para autos, pick ups, camiones y tractos las 24 hrs los 365 días del año."
				},
				{
					text:"Daños por la carga (aplica solo en unidades de carga)."
				},
				{
					text:"Maniobras de Carga y descarga (aplica solo en unidades de carga)."
				},
				{
					text:"Entre otras."
				}
			]
		},
		{
			title:"Beneficios",
			more:[
				{
					text:"Notificación de siniestros vía SMS y correo electrónico."
				},
                {
                    text:"Reducción del deducible de Robo Total al contar con algún dispositivo de localización autorizado por GNP."
                }
			]
		},
	]
};