

export const VidaCategory= {
    name:"Vida",
    sections: [
        {
            name: "Ahorro",
            products: [
                { name:"Dotal", id: "DotalSection"},
				{ name:"Vida a tus sueños", id:"DreamsSection"},
				{ name:"Impulsa", id:"ImpulseSection"}
            ]
        },
        {
            name: "Educación",
            products: [
                { name:"Profesional", id:"ProfessionalSection"},
				{ name:"Profesional Abuelos", id:"ProGrandPaSection"}
            ]
        },
        {
            name: "Protección",
            products: [
                { name:"Platino", id:"PlatinumSection"},
				{ name:"Privilegio", id:"PrivilegeSection"}
            ]
        },
        {
            name: "Protección y Ahorro",
            products: [
                { name:"Ordinario de Vida", id:"OrdinaryLifeSection"},
				{ name:"Platino Universal", id:"UniversalSection"},
				{ name:"Privilegio Universal", id:"UniversalPrivilegeSection"},
				{ name:"Trasciende", id:"TranscendsSection"},
				{ name:"Vida Inversión", id:"InvestmentLifeSection"},
				{ name:"Visión Plus", id:"VisionPlusSection"}
            ]
        },
        {
            name: "Retiro",
            products: [
                { name:"Consolida", id:"ConsolidateSection"},
				{ name:"Consolida Total", id:"TotalConsolidateSection"},
				{ name:"Elige", id:"ChooseSection"},
				{ name:"Proyecta", id:"ProjectsSection"},
				{ name:"Proyecta Afecto", id:"AffectionSection"}
            ]
        },
        {
            name: "Multiestrategia",
            products: [
                { name:"Capitaliza", id:"CapitalizeSection"}
            ]
        },
        {
            name: "Salud en Vida",
            products: [
                { name:"Salud en Vida", id:"HealtLifeSection"}
            ]
        },
		{
            name: "Continuidad Patrimonial",
            products: [
                { name:"Continuidad Patrimonial", id:"ContinuitySection"}
            ]
        }
    ]

};

/*Ahorro*/
export const DotalSection = {
	title: 'Dotal',

	info:[
		{
			title:"Descripción general",
			description:"Aliado que le ayuda a cumplir las diferentes metas se plantee en la vida, a través de un seguro de ahorro que le permitirá:",
			more:[
				{
					title:"",
					text:"Proteger a su familia en caso de que llegara a faltar"
				},
				{
					title:"",
					text:"Generar un ahorro garantizado"
				}
			]
		},
		{
			title:"Caracteristicas",
			more:[
				{
					title:"Edades:",
					text:"De 18 a 70 años"
				},
				{
					title:"Plazo:",
					text:"10, 15 y 20 años"
				},
				{
					title:"Moneda:",
					text:"Nacional y Dólares"
				},
				{
					title:"S.A. mínima:",
					text:"$400,000 / 20,000 dlls."
				}
			]
		},
		{
			title:"Coberturas",
			more:[
				{
					text:"Fallecimiento"
				},
				{
					text:"Supervivencia"
				},
				{
					text:"CUG, SEV"
				},
				{
					text:"ISE, BIT, PAM, IMA, DIBA, CM, DCM, BITPAM, ISEPAM, MAC y GAF"
				},
				{
					text:"Vidas Conjuntas"
				}
			]
		}
	]
};

export const DreamsSection = {
	title: 'Vida a tus sueños',

	info:[
		{
			title:"Descripción general",
			description:"Plan que le ayuda a potencializar sus recursos:",
			more:[
				{
					title:"",
					text:"Protege la estabilidad de su familia si llegara a faltar."
				},
				{
					title:"",
					text:"Permite generar un ahorro garantizado."
				}
			]
		},
		{
			title:"Caracteristicas",
			more:[
				{
					title:"Edades:",
					text:"De 18 a 70 años"
				},
				{
					title:"Plazo:",
					text:"7, 8 y 9 años"
				},
				{
					title:"Moneda:",
					text:"Dólares"
				},
				{
					title:"S.A. mínima:",
					text:"$15,000 dlls."
				}
			]
		},
		{
			title:"Coberturas",
			more:[
				{
					text:"Fallecimiento"
				},
				{
					text:"Supervivencia"
				},
				{
					text:"CUG, SEV"
				},
				{
					text:"ISE, BIT, PAM, IMA, DIBA, CM, DCM, BITPAM, ISEPAM, MAC y GAF"
				},
				{
					text:"Vidas Conjuntas"
				}
			]
		}
	]
};

export const ImpulseSection = {
	title: 'Impulsa',

	info:[
		{
			title:"Descripción general",
			description:"Aliado que le ayudará a maximizar el rendimiento a mediano y largo plazo de su ahorro, ofreciendo además la estabilidad que sus seres queridos necesitan en caso de llegar a faltar, ya que:",
			more:[
				{
					title:"",
					text:"Permite generar un ahorro garantizado, realizando un pago único."
				},
				{
					title:"",
					text:"Obtiene rendimientos por encima de las tasas bancarias."
				},
				{
					title:"",
					text:"Brinda protección por fallecimiento de hasta el doble de se aportación sin costo."
				}
			]
		},
		{
			title:"Caracteristicas",
			more:[
				{
					title:"Edades:",
					text:"De 18 a 70 años"
				},
				{
					title:"Plazo:",
					text:"10 años"
				},
				{
					title:"Moneda:",
					text:"Nacional"
				},
				{
					title:"S.A. mínima:",
					text:"$50,000 M.N."
				}
			]
		},
		{
			title:"Coberturas",
			more:[
				{
					text:"Fallecimiento"
				},
				{
					text:"Supervivencia"
				},
				{
					text:"Anticipo inmediato al fallecimiento"
				},
				{
					text:"Anticipo por enfermedad en fase terminal"
				},
				{
					text:"Vidas Conjuntas"
				}
			]
		}
	]
};

/*Educación*/
export const ProfessionalSection = {
	title: 'Profesional',

	info:[
		{
			title:"Descripción general",
			description:"Te presentamos una opción para que pueda garantizar la mejor preparación educativa de sus hijos ya que este plan le ofrece:",
			more:[
				{
					title:"",
					text:"Proteger a sus seres queridos."
				},
				{
					title:"",
					text:"Un fondo que se entregará al momento de que el menor cumpla la edad elegida para recibir dicho beneficio."
				}
			]
		},
		{
			title:"Caracteristicas",
			more:[
				{
					title:"Edades:",
					text:""
				},
				{
					title:"Menor:",
					text:"De 0 meses de gestación a 23 años."
				},
				{
					title:"Solicitante:",
					text:"De 18 a 70 años (pagos regulares) y 18 a 80 años (pagos limitados)."
				},
				{
					title:"Plazo de Cobertura:",
					text:"7 a 30 años"
				},
				{
					title:"Plazo de Pago:",
					text:"1, 5, 10 años y durante toda la vigencia."
				},
				{
					title:"Moneda:",
					text:"Nacional y Dólares."
				},
				{
					title:"S.A. mínima:",
					text:"$60,000 / 12,000 dlls."
				}
			]
		},
		{
			title:"Coberturas",
			more:[
				{
					text:"Fallecimiento"
				},
				{
					text:"Supervivencia"
				},
				{
					text:"BEF, BEI, BIT, CUG, SEV"
				},
				{
					text:"ISE, IMA, DIBA, CM, DCM, MAC"
				},
				{
					text:"Vidas Conjuntas"
				}
			]
		}
	]
};

export const ProGrandPaSection = {
	title: 'Profesional Abuelos',

	info:[
		{
			title:"Descripción general",
			description:"Te presentamos una opción para que pueda generar un ahorro garantizado para apoyar a su nieto o nieta en su educación o proyectos, desde su gestación. Profesional Abuelos le ofrece:",
			more:[
				{
					title:"",
					text:"Un fondo garantizado que se entregará al momento de que el menor cumpla la edad elegida para recibir dicho beneficio."
				}
			]
		},
		{
			title:"Caracteristicas",
			more:[
				{
					title:"Edades:",
					text:""
				},
				{
					title:"Menor:",
					text:"De 0 meses de gestación a 20 años."
				},
				{
					title:"Solicitante:",
					text:"De 50 a 99 años."
				},
				{
					title:"Plazo de Cobertura:",
					text:"10 a 30 años"
				},
				{
					title:"Plazo de Pago:",
					text:"1, 5, 10 años."
				},
				{
					title:"Moneda:",
					text:"Nacional y Dólares."
				},
				{
					title:"S.A. mínima:",
					text:"$60,000 / 12,000 dlls."
				}
			]
		},
		{
			title:"Coberturas",
			more:[
				{
					text:"Supervivencia"
				},
				{
					text:"MAC"
				},
				{
					text:"BEF, BEI, ISE, IMA, DIBA, CM, DCM"
				}
			]
		}
	]
};

/*Protección*/
export const PlatinumSection = {
	title: 'Platino',

	info:[
		{
			title:"Descripción general",
			description:"Plan que ofrece una alta protección para que sus seres queridos estén siempre protegidos.",
			more:[

			]
		},
		{
			title:"Caracteristicas",
			more:[
				{
					title:"Edades:",
					text:"De 18 a 70 años."
				},
				{
					title:"Plazo:",
					text:"10 y 20 años."
				},
				{
					title:"Moneda:",
					text:"Nacional y Dólares."
				},
				{
					title:"S.A. mínima:",
					text:"$5,000,000 / 500,000 dlls."
				}
			]
		},
		{
			title:"Coberturas",
			more:[
				{
					text:"Fallecimiento"
				},
				{
					text:"CUG, SEV"
				},
				{
					text:"ISE, BIT, IMA, DIBA, CM, DCM"
				},
				{
					text:"Vidas Conjuntas"
				}
			]
		}
	]
};

export const PrivilegeSection = {
	title: 'Privilegio',

	info:[
		{
			title:"Descripción general",
			description:"Es un plan que le ofrece alta protección, brindándole la oportunidad de elegir el plazo que más se ajuste a sus necesidades y así evitar dañar la estabilidad de su familia aún ya no esté.",
			more:[

			]
		},
		{
			title:"Caracteristicas",
			more:[
				{
					title:"Edades:",
					text:"De 18 a 70 años."
				},
				{
					title:"Plazo:",
					text:"1, 5, 10, 15, 20 y 30 años o incluso a los 65 años."
				},
				{
					title:"Moneda:",
					text:"Nacional y Dólares."
				},
				{
					title:"S.A. mínima:",
					text:"$400,000 / 50,000 dlls."
				}
			]
		},
		{
			title:"Coberturas",
			more:[
				{
					text:"Fallecimiento"
				},
				{
					text:"CUG, SEV"
				},
				{
					text:"ISE, BIT, IMA, DIBA, CM, DCM"
				},
				{
					text:"Vidas Conjuntas"
				}
			]
		}
	]
};

/*Protección y Ahorro*/
export const OrdinaryLifeSection = {
	title: 'Ordinario de Vida',

	info:[
		{
			title:"Descripción general",
			description:"Es un respaldo que ofrece una protección de por vida, garantizando su tranquilidad además de permitir a sus seres queridos mantener su nivel de vida actual cuándo ya no este.",
			more:[

			]
		},
		{
			title:"Caracteristicas",
			more:[
				{
					title:"Edades:",
					text:"De 18 a 70 años."
				},
				{
					title:"Plazo:",
					text:"Edad Alcanzada 100 años."
				},
				{
					title:"Moneda:",
					text:"Nacional y Dólares."
				},
				{
					title:"S.A. mínima:",
					text:"$400,000 / 50,000 dlls."
				}
			]
		},
		{
			title:"Coberturas",
			more:[
				{
					text:"Fallecimiento"
				},
				{
					text:"CUG, SEV"
				},
				{
					text:"ISE, BIT, IMA, DIBA, CM, DCM, MAC"
				},
				{
					text:"Vidas Conjuntas"
				}
			]
		}
	]
};

export const UniversalSection = {
	title: 'Platino Universal',

	info:[
		{
			title:"Descripción general",
			description:"Más que un seguro es un instrumento con alta protección y ahorro para que su patrimonio y el de sus seres queridos SIEMPRE estén protegidos, ofreciéndole:",
			more:[
				{
					title:"",
					text:"¡Los mejores costos de protección! Reconociendo su edad, género y estado de salud."
				},
				{
					title:"",
					text:"Alta recuperación de primas a largo plazo. ¡Garantizados por Contrato!."
				},
				{
					title:"",
					text:"Flexibilidad para incrementar su ahorro realizando aportaciones adicionales, con una tasa histórica de 10 años por arriba de la inflación: 4.96% rendimiento promedio."
				}
			]
		},
		{
			title:"Caracteristicas",
			more:[
				{
					title:"Edades:",
					text:"De 18 a 70 años."
				},
				{
					title:"Plazo:",
					text:"Edad Alcanzada 95 años."
				},
				{
					title:"Moneda:",
					text:"Dólares."
				},
				{
					title:"S.A. mínima:",
					text:"$500,000 dlls."
				}
			]
		},
		{
			title:"Coberturas",
			more:[
				{
					text:"Fallecimiento"
				},
				{
					text:"CUG, SEV"
				},
				{
					text:"ISE, BIT, IMA, DIBA, CM, DCM, MAC"
				},
				{
					text:"Vidas Conjuntas"
				}
			]
		}
	]
};

export const UniversalPrivilegeSection = {
	title: 'Privilegio Universal',

	info:[
		{
			title:"Descripción general",
			description:"Un plan de protección y ahorro, que le ofrece la tranquilidad de saber que sus seres queridos estarán protegidos aún cuando ya no esté, ofreciéndole:",
			more:[
				{
					title:"",
					text:"¡Los mejores costos de protección! Reconociendo su edad, género y estado de salud."
				},
				{
					title:"",
					text:"Alta recuperación de primas pagadas a largo plazo. ¡Garantizados por Contrato!."
				},
				{
					title:"",
					text:"Flexibilidad para incrementar su protección y ahorro en el tiempo, realizando aportaciones adicionales, de las cuales podrá disponer en el momento que las requiera."
				}
			]
		},
		{
			title:"Caracteristicas",
			more:[
				{
					title:"Edades:",
					text:"De 18 a 70 años."
				},
				{
					title:"Plazo:",
					text:"Edad Alcanzada 95 años."
				},
				{
					title:"Moneda:",
					text:"Nacional y Dólares."
				},
				{
					title:"S.A. mínima:",
					text:" $1,000,000 / 100,000 dlls."
				}
			]
		},
		{
			title:"Coberturas",
			more:[
				{
					text:"Fallecimiento"
				},
				{
					text:"CUG, SEV"
				},
				{
					text:"ISE, BIT, IMA, DIBA, CM, DCM, MAC"
				},
				{
					text:"Vidas Conjuntas"
				}
			]
		}
	]
};

export const TranscendsSection = {
	title: 'Trasciende',

	info:[
		{
			title:"Descripción general",
			description:"Plan de protección y ahorro que le permitirá tener la estabilidad financiera para obtener grandes beneficios para usted y su familia, así como estar protegido de por vida pagándolo en menores plazos de tiempo.",
			more:[

			]
		},
		{
			title:"Caracteristicas",
			more:[
				{
					title:"Edades:",
					text:"De 18 a 70 años."
				},
				{
					title:"Plazo de seguro:",
					text:"Edad Alcanzada 100 años."
				},
				{
					title:"Plazo de pago:",
					text:"Pagos Limitados 1, 5, 10, 15, 20 años y Edad Alcanzada 65 años"
				},
				{
					title:"Moneda:",
					text:"Nacional y Dólares."
				},
				{
					title:"S.A. mínima:",
					text:" $400,000 / 20,000 dlls."
				}
			]
		},
		{
			title:"Coberturas",
			more:[
				{
					text:"Fallecimiento"
				},
				{
					text:"CUG, SEV"
				},
				{
					text:"ISE, BIT, IMA, DIBA, CM, DCM, MAC"
				},
				{
					text:"Vidas Conjuntas"
				}
			]
		}
	]
};

export const InvestmentLifeSection = {
	title: 'Vida Inversión',

	info:[
		{
			title:"Descripción general",
			description:"Instrumento que le ofrece un doble beneficio ya que puede:",
			more:[
				{
					title:"",
					text:"Invertir su dinero para alcanzar metas financieras, concretar proyectos e incrementar su patrimonio."
				},
				{
					title:"",
					text:"Contar con la protección adecuada."
				}
			]
		},
		{
			title:"Caracteristicas",
			more:[
				{
					title:"Edades:",
					text:"De 18 a 70 años."
				},
				{
					title:"Plazo:",
					text:"Edad Alcanzada 65 y 95 años."
				},
				{
					title:"Moneda:",
					text:"Nacional."
				},
				{
					title:"S.A. mínima:",
					text:" $400,000 M.N."
				}
			]
		},
		{
			title:"Coberturas",
			more:[
				{
					text:"Fallecimiento"
				},
				{
					text:"Supervivencia"
				},
				{
					text:"CUG, SEV"
				},
				{
					text:"ISE, BIT, IMA, DIBA, CM, DCM"
				}
			]
		}
	]
};

export const VisionPlusSection = {
	title: 'Visión Plus',

	info:[
		{
			title:"Descripción general",
			description:"Un aliado que brinda la tranquilidad de saberse protegido durante su etapa productiva y le ayuda a concretar sus proyectos al construir un ahorro, ofreciéndole:",
			more:[
				{
					title:"",
					text:"¡Los mejores costos de protección! Reconociendo su edad, género y estado de salud."
				},
				{
					title:"",
					text:"Atractivos montos de recuperación de primas, lo que se traduce en un seguro a un ¡costo muy bajo!."
				},
				{
					title:"",
					text:"Un ahorro libre de impuestos."
				}
			]
		},
		{
			title:"Caracteristicas",
			more:[
				{
					title:"Edades:",
					text:"De 18 a 70 años."
				},
				{
					title:"Plazo:",
					text:"20 años y Edad Alcanzada 65 años."
				},
				{
					title:"Moneda:",
					text:"Nacional."
				},
				{
					title:"S.A. mínima:",
					text:" $400,000 M.N."
				}
			]
		},
		{
			title:"Coberturas",
			more:[
				{
					text:"Fallecimiento"
				},
				{
					text:"CUG, SEV"
				},
				{
					text:"ISE, BIT, IMA, DIBA, CM, DCM, MAC"
				},
				{
					text:"Vidas Conjuntas"
				}
			]
		}
	]
};

/*Retiro*/
export const ConsolidateSection = {
	title: 'Consolida',

	info:[
		{
			title:"Descripción general",
			description:"Lo prepara para recibir el retiro que merece y consolidar su futuro. Ahora es el mejor momento para planear su retiro con este plan flexible que le ofrece:",
			more:[
				{
					title:"",
					text:"Protección."
				},
				{
					title:"",
					text:"Ahorro garantizado."
				},
				{
					title:"",
					text:"Rentas vitalicias garantizadas."
				}
			]
		},
		{
			title:"Caracteristicas",
			more:[
				{
					title:"Edades:",
					text:"De 18 a 60 años."
				},
				{
					title:"Plazo:",
					text:"Edad Alcanzada 65 años."
				},
				{
					title:"Moneda:",
					text:"Nacional y Dólares."
				},
				{
					title:"S.A. mínima:",
					text:"$400,000 / 20,000 dlls."
				}
			]
		},
		{
			title:"Coberturas",
			more:[
				{
					text:"Fallecimiento"
				},
				{
					text:"Supervivencia"
				},
				{
					text:"CUG, SEV"
				},
				{
					text:"ISE, BIT, IMA, DIBA, PAM, CM, DCM, BITPAM, ISEPAM"
				}
			]
		}
	]
};

export const TotalConsolidateSection = {
	title: 'Consolida Total',

	info:[
		{
			title:"Descripción general",
			description:"Lo prepara para recibir el retiro que merece y consolidar su futuro. Ahora es el mejor momento para planear su retiro con este plan flexible que le ofrece:",
			more:[
				{
					title:"",
					text:"Protección."
				},
				{
					title:"",
					text:"Ahorro garantizado."
				},
				{
					title:"",
					text:"Rentas vitalicias garantizadas."
				},
				{
					title:"",
					text:"Plan afecto a los Artículos 185  y 151 de la LISR."
				}
			]
		},
		{
			title:"Caracteristicas",
			more:[
				{
					title:"Edades:",
					text:"De 18 a 60 años."
				},
				{
					title:"Plazo:",
					text:"Edad Alcanzada 65 años."
				},
				{
					title:"Moneda:",
					text:"Nacional."
				},
				{
					title:"S.A. mínima:",
					text:"$400,000 M.N."
				}
			]
		},
		{
			title:"Coberturas",
			more:[
				{
					text:"Fallecimiento"
				},
				{
					text:"Supervivencia"
				},
				{
					text:"CUG, SEV"
				},
				{
					text:"ISE, BIT, IMA, DIBA, PAM, CM, DCM, BITPAM, ISEPAM"
				}
			]
		}
	]
};

export const ChooseSection = {
	title: 'Elige',

	info:[
		{
			title:"Descripción general",
			description:"Plan flexible de Protección y ahorro que ayudará a tu cliente a generar un monto garantizado para el retiro.",
			more:[

			]
		},
		{
			title:"Caracteristicas",
			more:[
				{
					title:"Edades:",
					text:"De 18 a 70 años."
				},
				{
					title:"Plazo:",
					text:"20 años y Edad Alcanzada 65 años."
				},
				{
					title:"Moneda:",
					text:"Nacional."
				},
				{
					title:"S.A. mínima:",
					text:"$400,000 M.N."
				}
			]
		},
		{
			title:"Coberturas",
			more:[
				{
					text:"Fallecimiento"
				},
				{
					text:"Supervivencia"
				},
				{
					text:"CUG, SEV"
				},
				{
					text:"ISE, BIT, IMA, DIBA, CM, DCM"
				}
			]
		}
	]
};

export const ProjectsSection = {
	title: 'Proyecta',

	info:[
		{
			title:"Descripción general",
			description:"Usted merece tener un retiro digno, por ello es importante que empiece a tomar acciones al respecto. Este plan le ofrece:",
			more:[
				{
					title:"",
					text:"Protección para sus seres queridos."
				},
				{
					title:"",
					text:"Ahorro garantizado."
				},
				{
					title:"",
					text:"Diversos plazos de pago."
				},
				{
					title:"",
					text:"Rentas vitalicias garantizadas."
				}
			]
		},
		{
			title:"Caracteristicas",
			more:[
				{
					title:"Edades:",
					text:"De 18 a 70 años."
				},
				{
					title:"Plazo:",
					text:"Edad Alcanzada 55, 60, 65 o 70 años con Pagos Limitados 10 años."
				},
				{
					title:"Moneda:",
					text:"Nacional y Dólares."
				},
				{
					title:"S.A. mínima:",
					text:"$400,000 / 20,000 dlls."
				}
			]
		},
		{
			title:"Coberturas",
			more:[
				{
					text:"Fallecimiento"
				},
				{
					text:"Supervivencia"
				},
				{
					text:"CUG, SEV"
				},
				{
					text:"ISE, BIT, IMA, DIBA, PAM, CM, DCM, BITPAM, ISEPAM y MAC"
				}
			]
		}
	]
};

export const AffectionSection = {
	title: 'Proyecta Afecto',

	info:[
		{
			title:"Descripción general",
			description:"Usted merece tener un retiro digno, por ello es importante que empiece a tomar acciones al respecto. Este plan le ofrece:",
			more:[
				{
					title:"",
					text:"Protección para sus seres queridos."
				},
				{
					title:"",
					text:"Ahorro garantizado."
				},
				{
					title:"",
					text:"Diversos plazos de pago."
				},
				{
					title:"",
					text:"Rentas vitalicias garantizadas."
				},
				{
					title:"",
					text:"Afecto al art. 185 de la LISR."
				}
			]
		},
		{
			title:"Caracteristicas",
			more:[
				{
					title:"Edades:",
					text:"De 18 a 70 años."
				},
				{
					title:"Plazo:",
					text:"10 años y Edad Alcanzada 55, 60, 65, 70 con Pagos Limitados 10 años."
				},
				{
					title:"Moneda:",
					text:"Nacional y Dólares."
				},
				{
					title:"S.A. mínima:",
					text:"$400,000 / 20,000 dlls."
				}
			]
		},
		{
			title:"Coberturas",
			more:[
				{
					text:"Fallecimiento"
				},
				{
					text:"Supervivencia"
				},
				{
					text:"CUG, SEV"
				},
				{
					text:"ISE, BIT, IMA, DIBA, PAM, CM, DCM, BITPAM, ISEPAM"
				}
			]
		}
	]
};

/*Retiro*/
export const CapitalizeSection = {
	title: 'Capitaliza',

	info:[
		{
			title:"Descripción general",
			description:"Aliado que ayudará a cumplir los diversos objetivos que tiene a lo largo de su vida y contar con la tranquilidad de que usted y su familia estarán tranquilos ya que le ofrece:",
			more:[
				{
					title:"",
					text:"Protección para sus seres queridos."
				},
				{
					title:"",
					text:"Estrategias de ahorro."
				},
				{
					title:"",
					text:"Premios por su lealtad."
				}
			]
		},
		{
			title:"Caracteristicas",
			more:[
				{
					title:"Edades:",
					text:"De 18 a 80 años."
				},
				{
					title:"Plazo:",
					text:""
				},
				{
					title:"Temporal:",
					text:"10, 15, 20 y Edad Alcanzada 65 años."
				},
				{
					title:"A edad 100:",
					text:"10, 15, 20 y Edad Alcanzada 65 años."
				},
				{
					title:"Moneda:",
					text:"Nacional."
				},
				{
					title:"S.A. mínima:",
					text:"$200,000 M.N."
				}
			]
		},
		{
			title:"Coberturas",
			more:[
				{
					text:"Fallecimiento"
				},
				{
					text:"Supervivencia"
				},
				{
					text:"CUG, SEV"
				},
				{
					text:"ISE, BIT, IMA, DIBA, CM, DCM, BITIMA, BITDIBA, BITCM y BITDCM"
				}
			]
		}
	]
};

/*Salud en Vida*/
export const HealtLifeSection = {
	title: 'Salud en Vida',

	info:[
		{
			title:"Descripción general",
			description:"Es un plan que le ofrece:",
			more:[
				{
					title:"",
					text:"Protección en caso de fallecimiento."
				},
				{
					title:"",
					text:"Fondo de ahorro garantizado para pagar su plan de GMM una vez que llegue el retiro."
				}
			]
		},
		{
			title:"Caracteristicas",
			more:[
				{
					title:"Edades:",
					text:"De 18 a 80 años."
				},
				{
					title:"Plazo:",
					text:"Edad Alcanzada 55, 60 y 65 años."
				},
				{
					title:"Moneda:",
					text:"Nacional y Dólares."
				},
				{
					title:"S.A. mínima:",
					text:"$400,000 / 50,000 dlls."
				}
			]
		},
		{
			title:"Coberturas",
			more:[
				{
					text:"Fallecimiento"
				},
				{
					text:"Supervivencia"
				},
				{
					text:"CUG, SEV"
				},
				{
					text:"ISE, BIT, IMA, DIBA, PAM, CM, DCM, BITPAM, ISEPAM y MAC"
				}
			]
		}
	]
};

/*Continuidad Patrimonial*/
export const ContinuitySection = {
	title: 'Continuidad Patrimonial',

	info:[
		{
			title:"Descripción general",
			description:"Consiste en la inversión y administración sin costo del monto alcanzado en la cobertura de supervivencia, usted podrá maximizar su ahorro, además de contar con protección adicional en caso de fallecimiento y gozar de beneficios fiscales. Beneficios:",
			more:[
				{
					title:"",
					text:"Administración sin costo."
				},
				{
					title:"",
					text:"Acreditación de intereses al ahorro acumulado."
				}
				,
				{
					title:"",
					text:"Protección por fallecimiento del 5% del saldo acumulado en la póliza durante el periodo de Continuidad Patrimonial."
				}
				,
				{
					title:"",
					text:"Disponibilidad parcial y total del ahorro e intereses generados sin plazos forzosos."
				}
				,
				{
					title:"",
					text:"Monitoreo del ahorro a través de Call Center."
				}
			]
		},
		{
			title:"",
			more:[
			]
		},
		{
			title:"",
			more:[
			]
		}
	]
};