import React, { Component } from 'react'
import logo from "../Logo.ico";
import { Link, animateScroll as scroll } from "react-scroll";
import NavBar from './NavBar';

export default class Header extends Component {
    scrollToTop = () => {
        scroll.scrollToTop();
      };
      render() {
        return (
            <div className='header'>
                <NavBar ></NavBar>
            </div>
           
        //   <nav className="nav" id="navbar">
        //     <div className="nav-content">
        //         <img
        //             src={logo}
        //             className="nav-logo"
        //             alt="Logo"
        //             onClick={this.scrollToTop}
        //         />
        //       <ul className="nav-items">
        //         <li className="nav-item">
        //           <Link
        //             activeClass="active"
        //             to="Seguros"
        //             spy={true}
        //             smooth={true}
        //             offset={-70}
        //             duration={500}
        //           >
        //             Seguros
        //           </Link>
        //         </li>
        //         <li className="nav-item">
        //           <Link
        //             activeClass="active"
        //             to="Inversiones"
        //             spy={true}
        //             smooth={true}
        //             offset={-70}
        //             duration={500}
        //           >
        //             Inversiones
        //           </Link>
        //         </li>
        //         <li className="nav-item">
        //           <Link
        //             activeClass="active"
        //             to="Nosotros"
        //             spy={true}
        //             smooth={true}
        //             offset={-70}
        //             duration={500}
        //           >
        //             Nosotros
        //           </Link>
        //         </li>
        //         <li className="nav-item">
        //           <Link
        //             activeClass="active"
        //             to="Contacto"
        //             spy={true}
        //             smooth={true}
        //             offset={-70}
        //             duration={500}
        //           >
        //             Contacto
        //           </Link>
        //         </li>
        //       </ul>
        //     </div>
        //   </nav>
        );
    }
}